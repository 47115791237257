<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar nomeRota="TipoMandato" />
      <h1>Visualizar Tipo de Mandato</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-1">
              <p>Código:
                <span>{{ tipoMandato.codigo }}</span>
              </p>
            </div>
          </div>
          <div class="dados">
            <div class="flexbox-1">
              <p>Nome:
                <span>{{ tipoMandato.nome }}</span>
              </p>
            </div>
          </div>
          <div class="dados">
            <div class="flexbox-1">
              <p>Ativo:
                <span>{{ tipoMandato.ativo ? 'Sim' : 'Não' }}</span>
              </p>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="bt-editar">
        <div class="div-center">
          <div :id="tipoMandato.id">
            <router-link :to="{ name: 'TipoMandatoEdit', params: { id: tipoMandato.id }}">
              <button id="botao-editar">
                Editar
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="tipoMandato.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="tipoMandato.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="tipoMandato.created_at"
        :updated_at="tipoMandato.updated_at"
      />
      </div>
  </div>
</template>

<script>
import BotaoVoltar from '../../components/BotaoVoltar.vue';
import Footer from '../../components/Footer.vue';
import { parseISO, format } from "date-fns";
import TipoMandatoApi from "../../services/TipoMandatoApi";

export default {
  name: "ExibirTipoMandato",
  props: {
    tipoMandato: Object,
  },
  components: {
    BotaoVoltar,
    Footer,
  },
  data() {
    return {
      tipoMandatoApi: new TipoMandatoApi(),
    };
  },
  methods: {
    formatarDataHora(value) {
      return format(parseISO(value), "dd/MM/yyyy 'às' HH:mm:ss");
    },
  },
};
</script>


<style lang="scss" scoped>
@media screen and (min-width: 1066px) {
  .fora {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f8f8;
    margin: 0;
    padding: 1.25rem;

    .topo-header {
      display: flex;
      align-items: center;

      img {
        height: 1.5rem;
      }

      h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.8rem;
      }
    }
    .form {
      .div-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      fieldset {
        background-color: #dbdbdb;
        padding: 1rem 3rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .dados {
          border-radius: 0.7rem;
          padding-top: 1%;
          display: grid;

          p {
            font-family: "Montserrat-Medium";
            font-size: 1.1rem;
            font-weight: bold;
            display: inline-block;
            width: fit-content;
            margin: 0 0 0.5rem 0;
          }

          span {
            margin-left: .3rem;
            font-size: 1.1rem;
            font-weight: normal;
          }

          .flexbox-1 {
            display: grid;
            grid-template-columns: .5fr .5fr;
            gap: 2rem;
          }
          .flexbox-2 {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr;
            gap: 2rem;
          }
          .flexbox-3 {
            display: grid;
            grid-template-columns: .3fr .7fr;
            gap: 2rem;
          }
          .convenio {
            padding: 1rem;
            box-shadow: black 0px 5px 15px;
            margin: 0 1rem 2rem 1rem;
            p {
              margin: 0 0 1rem 0;
            }
            span {
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }
          }
        }
      }
    }
    .bt-editar {
      text-align: center;
      margin: 1.5rem 0 0 0;

      button {
        background-color: #005516;
        color: white;
        border: none;
        font-size: 1rem;
        padding: 0.4rem 2rem;
        border-radius: 2rem;
        margin: 0.2rem;
      }

      label {
        display: none;
        padding-left: 10px;
        width: 10px;
      }

      #botao-editar:hover {
        background-color: #4CAF50;
      }

      #botao-editar-tab:hover {
        background-color: #4CAF50;
      }
    }
    .footer {
      display: block;
      width: fit-content;
      height: 2rem;
      color: #000000;
      margin: 0 0 0 3rem;

      .criado-por {
        display: block;
        font-size: .9rem;
        /* Alterado as seguintes fontes para padronizar o rodapé de informações */
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }

      .atualizado-por {
        margin: 1rem 0 0 0;
        display: block;
        font-size: .9rem;
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }
    }
  }
  .inline {
    display: inline-block;
  }
}
@media screen and (min-width: 80px) and (max-width: 1065px) {
  .fora {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f8f8;
    margin: 0;
    padding: 1.25rem;

    .topo-header {
      display: flex;
      align-items: center;

      img {
        height: 1.5rem;
      }

      h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.5rem;
      }
    }
    .form {
      .div-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      fieldset {
        background-color: #dbdbdb;
        padding: 1rem 3rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .dados {
          border-radius: 0.7rem;
          padding-top: 1%;
          display: grid;

          p {
            font-family: "Montserrat-Medium";
            font-size: .9rem;
            font-weight: bold;
            display: inline-block;
            width: fit-content;
            margin: 0 0 2rem 0;
          }

          span {
            margin-left: .3rem;
            font-size: .9rem;
            font-weight: normal;
          }

          .flexbox-1 {
            display: grid;
            grid-template-columns: .5fr .5fr;
            gap: 2rem;
          }
          .flexbox-2 {
            display: grid;
            grid-template-columns: .3fr 1fr .3fr;
            gap: 2rem;
          }
          .flexbox-3 {
            display: grid;
            grid-template-columns: .3fr .7fr;
            gap: 2rem;
          }
          .convenio {
            padding: 1rem;
            box-shadow: black 0px 5px 15px;
            margin: 0 1rem 2rem 1rem;
            p {
              margin: 0 0 1rem 0;
            }
            span {
              margin-left: .3rem;
              font-size: .9rem;
              font-weight: normal;
            }
          }
        }
      }
    }
    .bt-editar {
      text-align: center;
      margin: 1.5rem 0 0 0;

      button {
        background-color: #005516;
        color: white;
        border: none;
        font-size: 1rem;
        padding: 0.4rem 2rem;
        border-radius: 2rem;
        margin: 0.2rem;
      }

      label {
        display: none;
        padding-left: 10px;
        width: 10px;
      }

      #botao-editar:hover {
        background-color: #4CAF50;
      }

      #botao-editar-tab:hover {
        background-color: #4CAF50;
      }
    }
    .footer {
      display: block;
      width: fit-content;
      height: fit-content;
      color: #000000;
      padding: 0;
      margin: 3rem 1rem 0 1rem;

      .criado-por {
        display: block;
        font-size: .9rem;
        /* Alterado as seguintes fontes para padronizar o rodapé de informações */
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }

      .atualizado-por {
        margin: 1rem 0 0 0;
        display: block;
        font-size: .9rem;
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }
    }
  }
  .inline {
    display: inline-block;
  }
}
</style>