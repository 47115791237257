<template>
    <div>
      <StatusMandatoForm :statusMandato="null" />
    </div>
  </template>
  
  <script>
  import StatusMandatoForm from "../../components/status_mandato/StatusMandatoForm.vue";
  
  export default {
    name: "StatusMandatoNew",
    components: {
      StatusMandatoForm,
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  