<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Recuperar Senha</h1>
        <form @submit.prevent="handleSubmit">
            <div class="item">
              <label for="email">Digite seu e-mail cadastrado no sistema:</label>
              <input type="email" name="email" id="email" class="form-control" placeholder="E-mail" v-model="email" />
            </div>

            <div class="mensagem">
              <p>Email é obrigatório!</p>
            </div>

            <div class="item">
              <button type="submit" class="btn-enviar">Enviar</button>
            </div>
        </form>
        <button type="button" class="btn-voltar" @click="voltar()">Voltar</button>
      </div>
      <div class="footer-mobile">
        <img
          title="Logo da Casa Civil e Estado de Goiás"
          src="../../assets/imagens/logo-casa-bco.png"
          class="logo"
          alt="Logo da Casa Civil e Estado de Goiás"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'RecuperarSenha',
    metaInfo() {
      return {
        title: "Colegiados",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Colegiados - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
        return {
            email: ''
        }
    },
    methods: {
      async handleSubmit() {
        const data = {
          email: this.email
        };

        let divMensagem = document.getElementsByClassName('mensagem')[0]

        if(this.email == "") {
          divMensagem.style.display = "block";
        }

        axios.post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/recuperar_senha`, data)
        .then(
          res => {    
            this.$router.push({
              path: "/sessoes/tela_aviso_email",
              params: {
                response: res,
              }
            });
          }
        ).catch(
          err => {
            console.log(err)
          }
        )
      },
      voltar() {
        history.back();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 700px) {
    .tela-login {
      display: flex;
      flex-direction: column;
      //background-image: url("../../assets/imagens/bk-login.png");
      //background-attachment: fixed;
      //background-size: cover;
      //background-repeat: no-repeat;
      //background-position: left;
      background-color: #125b19;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      .mensagem {
        display: none;
        position: relative;
        margin: 1rem 1rem -3rem 0rem;
        float: right;
      }
      .left {
        display: block;
        height: 10rem;
        .logo {
          width: 15rem;
          margin: 1rem 0 0;
        }

        .logo-casa {
          display: none;
        }
      }
      .right {
        background-color: white;
        //max-width: 22rem;
        padding: 2rem;
        min-width: 25rem;
        width: 50%;
        height: 20rem;
        border-radius: 4rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);
        h1 {
          margin: 0rem 0 2rem 0;
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
        }
        .item {
          display: grid;
          margin: 1rem 0;
          label {
            font-family: "Montserrat-Medium";
            color: #005516;
            margin-bottom: .5rem;
          }
          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }
          input {
            height: 3rem;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: 1.1rem;
          }
        }
        a {
          margin: .5rem 0 0 1rem;
          color: #005516;
          text-align: left;
          font-size: 1.2rem;
          width: fit-content;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
        .btn-enviar {
          background-color: #005516;
          color: white;
          font-family: 'Montserrat-Medium';
          font-size: 1.2rem;
          border: none;
          padding: .5rem 1.5rem;
          border-radius: 1rem;
          cursor: pointer;
          width: 14rem;
        }
        .btn-enviar:hover {
          background-color: #005517d5;
        }
        .btn-voltar {
          display: flex;
          background-color: #fff;
          color: #005516;
          font-family: 'Montserrat-Medium';
          font-size: 1rem;
          border: none;
          border-radius: 1rem;
          cursor: pointer;
          float: right;
          margin: 0rem 1rem 0rem 0;
          padding: 0;
        }
        .btn-voltar:hover {
          text-decoration: underline;
        }
      }
      .footer-mobile {
        img {
          width: 15rem;
          margin-top: 1rem;
        }
      }
    }
  }
  @media screen and (min-width: 60px) and (max-width: 699px) {
    .tela-login {
      display: flex;
      flex-direction: column;
      //background-image: url("../../assets/imagens/bk-login.png");
      //background-attachment: fixed;
      //background-size: cover;
      //background-repeat: no-repeat;
      //background-position: left;
      background-color: #125b19;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      .mensagem {
        display: none;
        position: relative;
        margin: 1rem 1rem -3rem 0rem;
        float: right;
      }
      .left {
        display: block;
        height: 10rem;
        .logo {
          width: 15rem;
          margin: 1rem 0 0;
        }

        .logo-casa {
          display: none;
        }
      }
      .right {
        background-color: white;
        //max-width: 22rem;
        padding: 2rem;
        min-width: 25rem;
        width: 50%;
        height: 20rem;
        border-radius: 4rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);
        h1 {
          margin: 0rem 0 2rem 0;
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
        }
        .item {
          display: grid;
          margin: 1rem 0;
          label {
            font-family: "Montserrat-Medium";
            color: #005516;
            margin-bottom: .5rem;
          }
          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }
          input {
            height: 3rem;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: 1.1rem;
          }
        }
        a {
          margin: .5rem 0 0 1rem;
          color: #005516;
          text-align: left;
          font-size: 1.2rem;
          width: fit-content;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
        .btn-enviar {
          background-color: #005516;
          color: white;
          font-family: 'Montserrat-Medium';
          font-size: 1.2rem;
          border: none;
          padding: .5rem 1.5rem;
          border-radius: 1rem;
          cursor: pointer;
          width: 14rem;
        }
        .btn-enviar:hover {
          background-color: #005517d5;
        }
        .btn-voltar {
          display: flex;
          background-color: #fff;
          color: #005516;
          font-family: 'Montserrat-Medium';
          font-size: 1rem;
          border: none;
          border-radius: 1rem;
          cursor: pointer;
          float: right;
          margin: 0rem 1rem 0rem 0;
          padding: 0;
        }
        .btn-voltar:hover {
          text-decoration: underline;
        }
      }
      .footer-mobile {
        img {
          width: 15rem;
          margin-top: 1rem;
        }
      }
    }
  }
  .mensagem {
    display: none;
    position: relative;
    margin: 1.5rem 0rem -3rem 1rem;
    p {
      color: #bd0000;
    }
  }
</style>
