<template>
    <div class="wrapper">
      <div class="topo-header centro">
        <botao-voltar nomeRota="TipoMandato" />
        <h1>{{ tipoMandatoForm.id ? "Editar" : "Novo" }} Tipo de Mandato</h1>
      </div>
      <div class="tab-dados-basicos">
        <div class="form centro">
          <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
          <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>
          <form @submit.prevent="enviarForm">
            <div class="caixa-form">
                <div class="flexbox">
                    <div class="item">
                        <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                        <input type="text" placeholder="Digite o nome" v-model="tipoMandatoForm.nome" />
                    </div>
                    <div class="item">
                        <label><b>{{ possuiAsterisco("Código:") }}</b></label>
                        <input type="number" min="1" max="9" v-model="tipoMandatoForm.codigo" @input="inRange(tipoMandatoForm.codigo)"  placeholder="Digite o código"  />
                    </div>
                
                    <div class="item">
                        <div class="radio">
                            <label class="radio-label"><b>{{ possuiAsterisco("Ativo") }}</b></label>
                            <div class="radio-item">
                                <div class="options">
                                    <label for="sim-ativo-tipo-mandato">Sim</label>
                                    <input type="radio" id="sim-ativo-tipo-mandato" name="radioOptions" value="true" v-model="tipoMandatoForm.ativo" />
                                </div>
                                <div class="options">
                                    <label for="nao-ativo-tipo-mandato">Não</label>
                                    <input type="radio" id="nao-ativo-tipo-mandato" name="radioOptions" value="false" v-model="tipoMandatoForm.ativo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bt-salvar">
              <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                <img id="spinner-loading" class="spinner-loading" src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                <p id="conteudo-botao">{{ tipoMandatoForm.id ? "Atualizar" : "Criar" }}</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
  
<script>
  import TipoMandatoApi from '../../services/TipoMandatoApi'; // Importando a API correta
  import BotaoVoltar from '../../components/BotaoVoltar.vue';
  import MsgErroFormularios from '../MsgErroFormularios';
  
  export default {
    name: 'TipoMandatoForm',
    props: {
      tipoMandato: {
        type: Object,
        required: false,
      },
      nomeRota: String,
    },
    data() {
      return {
        erro: false,
        msgErros: {
          codigo: '',
          nome: '',
          ativo: '',
          default: '',
        },
        tipoMandatoForm: this.tipoMandato 
        ? {
            id: this.tipoMandato.id,
            codigo: this.tipoMandato.codigo,
            nome: this.tipoMandato.nome,
            ativo: this.tipoMandato.ativo,
        }
        : {
            id: null,
            codigo: null,
            nome: null,
            ativo: null,
        }
      };
    },
    components: {
      BotaoVoltar,
      MsgErroFormularios,
    },
    created() {
        this.tipoMandatoApi = new TipoMandatoApi();
    },
    methods: {
      async enviarForm() {
        try {
          const flag = this.validarForm(this.tipoMandatoForm);
          if (flag === 1) {
            this.erro = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
          }
  
          const data = await this.tipoMandatoApi.setTipoMandato(this.tipoMandatoForm); // Chamando o método correto
  
          // Redirecionar para a rota de visualização após a submissão bem-sucedida
          this.$router.push({
            name: 'TipoMandatoShow',
            params: {
              id: data.id,
            },
          });
        } catch (err) {
          console.error('Erro ao enviar formulário:', err);
          console.error(err);
          this.erro = true;
          const { response } = err;
          if (response && response.data) {
            this.msgErros = response.data;
          } else {
            this.msgErros = 'Houve um erro ao salvar o registro. Por favor, tente novamente.';
          }
        }
      },
      validarForm(tipoMandatoForm) {
        let flag = 0;
        this.msgErros = {
          codigo: '',
          nome: '',
          ativo: '',
          default: '',
        };
  
        if (!tipoMandatoForm.codigo) {
            this.msgErros.codigo = 'O campo código não foi preenchido.';
            flag = 1;
        }
        if (!tipoMandatoForm.nome) {
            this.msgErros.nome = 'O campo nome não foi preenchido.';
            flag = 1;
        }
        if (tipoMandatoForm.ativo === null) {
            this.msgErros.ativo = 'O campo ativo não foi selecionado.';
            flag = 1;
        }

        return flag;
      },
      possuiAsterisco(texto) {
        return '* ' + texto;
      },
      fecharErro() {
        this.erro = false;
      },
      inRange(codigo) {
        const divMensagem = document.getElementById("mensagem");
  
        if (codigo > 9 || codigo <= 0) {
          divMensagem.style.display = "block";
        }
  
        if (codigo <= 9 && codigo > 0) {
          divMensagem.style.display = "none";
        }
  
        if (codigo == '') {
          divMensagem.style.display = "none";
        }
      },
    },
  };
</script>
  
  
<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    font-family: 'Karla', sans-serif;
}

@media screen and (min-width: "1066px") {
    .wrapper {
        width: 100%;
        min-height: 100vh;
        background-color: #f8f8f8;
        margin: 0;
        padding: 20px;
    }
    .item {
        flex: 1;
        margin-right: 1rem;
    }
    h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600 !important;
        margin-left: 1rem;
        font-size: 1.8rem;
    }

    .topo-header {
        display: flex;
        align-items: center;

        img {
            height: 1.5rem;
        }
    }

    .caixa-form {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .btn-carregar-dados {
            align-self: center;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            color: #fff;
            background-color: #139b2b;
            border: none;
            font-size: .8rem;
            border-radius: 2rem;
            padding: .2rem .5rem;
            margin: 1.5rem 0 0 0;

            p {
                text-align: center;
                margin: .2rem .2rem;
            }
        }

        .btn-carregar-dados:hover {
            background-color: #139b2cbc;
        }

        input,
        the-mask {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

        /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
        input[type="file"] {
            display: none;
        }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }
        .flexbox {
            display: grid;
            grid-template-columns: 3.6fr 0.4fr 1fr;
            gap: 2rem;
            margin: 0;

            .item {
                display: grid;
                margin: 0;
                width: -webkit-fill-available;

                .radio {
                    display: grid;
                    align-items: center;
                    height: fit-content;
                    width: fit-content;

                    .radio-label {
                        margin-bottom: 0.3rem;
                    }

                    .radio-item {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        .options {
                            display: flex;
                            align-items: center;
                            gap: .4rem;
                        }

                        label {
                            margin: 0;
                            font-size: .8rem;
                        }

                        input {
                            width: fit-content;
                            margin: 0;
                        }
                    }
                }

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                /*  .upload {
                                color: #ffa600;
                                border-radius: 0.5rem;
                                border: #ffa600 solid 0.124rem;
                                display: inline-block;
                                padding: 2px 6px;
                                font-size: 0.9rem;
                                transition: 0.2s;
                                margin-left: 0.4rem;
                                margin-right: 0.4rem;
                                font-family: "Montserrat-Medium";
                            }
                            .upload:hover{
                                color: white;
                                background-color: #ffa600;
                            } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }
            }
        }    
    }

    .bt-salvar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
            p {
                margin: 0;
            }
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .spinner-loading {
        visibility: hidden;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0rem .5rem 0rem -0.5rem;
    }

    #botao-salvar:hover {
        background-color: #4CAF50;
    }
}

@media screen and (min-width: "60px") and (max-width: "1065px") {
    .wrapper {
        width: 100%;
        min-height: 100vh;
        background-color: #f8f8f8;
        margin: 0;
        padding: 20px;
    }

    h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600; 
        margin-left: 1rem;
        font-size: 1.8rem;
    }

    .topo-header {
        display: flex;
        align-items: center;

        img {
            height: 1.5rem;
        }
    }

    .caixa-form {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem;
        border: 2px solid #00a339;

        .btn-carregar-dados {
            align-self: center;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            color: #fff;
            background-color: #139b2b;
            border: none;
            font-size: .8rem;
            border-radius: 2rem;
            padding: .2rem .8rem;
        }

        .btn-carregar-dados:hover {
            background-color: #139b2cbc;
        }

        input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

        /* input[type="file"]{
                    border-radius: 0.5rem;
                    border: none;
                    width: 44.27rem;
                } */
        input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
        }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }

        .item {
            display: grid;
            margin: 1rem 0;

            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }

            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }

            a:link {
                color: #ffa600;
            }

            a:link:hover {
                color: #e49503;
            }

            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }

            .upload {
                background-color: #ffa600;
                color: white;
                border: none;
                font-size: 0.9rem;
                padding: 2px 6px;
                border-radius: 0.2rem;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
            }

            /* .upload{
                    color: #ffa600;
                    border-radius: 0.5rem;
                    border: #ffa600 solid 0.124rem;
                    display: inline-block;
                    padding: 2px 6px;
                    font-size: 0.9rem;
                    transition: 0.2s;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                    font-family: "Montserrat-Medium";
                    }
                    .upload:hover{
                    color: white;
                    background-color: #ffa600;
                    } */
            .textarea {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 0 0 1px #000;
                border: 5px solid transparent;
                outline: none;
                padding: 4px;
                min-height: 150px;
                box-sizing: border-box;
                font-family: "Montserrat-Medium";
                font-size: 0.93rem;
                color: black;
            }

            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }

            .mx-datepicker {
                width: 100%;
            }

            .checkbox {
                input {
                    width: auto;
                }

                .check {
                    --background: #fff;
                    --border: #d1d6ee;
                    --border-hover: #bbc1e1;
                    --border-active: #1b5138;
                    --tick: #fff;
                    position: relative;
                    cursor: pointer;

                    input,
                    svg {
                        width: 21px;
                        height: 21px;
                        display: inline-block;
                    }

                    input {
                        appearance: none;
                        -moz-appearance: none;
                        position: relative;
                        outline: none;
                        background: var(--background);
                        border: none;
                        margin: 0;
                        transform: translateY(5px);
                        padding: 0;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: box-shadow 0.3s;
                        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                        &:hover {
                            --s: 2px;
                            --b: var(--border-hover);
                        }

                        &:checked {
                            --b: var(--border-active);
                        }
                    }

                    svg {
                        pointer-events: none;
                        fill: none;
                        stroke-width: 2px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke: var(--stroke, var(--border-active));
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 21px;
                        height: 21px;
                        transform: scale(var(--scale, 1)) translateZ(1);
                        margin-top: 0rem;
                    }

                    span {
                        transform: translateY(-5px);
                        display: inline;
                        margin-left: 0.5rem;
                    }

                    &.path {
                        input {
                            &:checked {
                                --s: 2px;
                                transition-delay: 0.4s;

                                &+svg {
                                    --a: 16.1 86.12;
                                    --o: 102.22;
                                }
                            }
                        }

                        svg {
                            stroke-dasharray: var(--a, 86.12);
                            stroke-dashoffset: var(--o, 86.12);
                            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                        }
                    }
                }
            }
        }

        .multiselect {
            width: calc(100vw - 5.5rem);
        }
    }

    .caixa-form-blocos {
        background-color: #efefef;
        padding: 1rem;
        border-radius: 0.2rem;

        input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

        /* input[type="file"]{
                    border-radius: 0.5rem;
                    border: none;
                    width: 44.27rem;
                } */
        input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
        }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }

        .item {
            display: block;
            margin: 1rem 0;

            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }

            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }

            a:link {
                color: #ffa600;
            }

            a:link:hover {
                color: #e49503;
            }

            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }

            .upload {
                background-color: #ffa600;
                color: white;
                border: none;
                font-size: 0.9rem;
                padding: 2px 6px;
                border-radius: 0.2rem;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
            }

            /* .upload{
                    color: #ffa600;
                    border-radius: 0.5rem;
                    border: #ffa600 solid 0.124rem;
                    display: inline-block;
                    padding: 2px 6px;
                    font-size: 0.9rem;
                    transition: 0.2s;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                    font-family: "Montserrat-Medium";
                    }
                    .upload:hover{
                    color: white;
                    background-color: #ffa600;
                    } */
            .textarea {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 0 0 1px #000;
                border: 5px solid transparent;
                outline: none;
                padding: 4px;
                min-height: 150px;
                box-sizing: border-box;
                font-family: "Montserrat-Medium";
                font-size: 0.93rem;
                color: black;
            }

            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }

            .mx-datepicker {
                width: 100%;
            }

            .checkbox {
                input {
                    width: auto;
                }

                .check {
                    --background: #fff;
                    --border: #d1d6ee;
                    --border-hover: #bbc1e1;
                    --border-active: #1b5138;
                    --tick: #fff;
                    position: relative;
                    cursor: pointer;

                    input,
                    svg {
                        width: 21px;
                        height: 21px;
                        display: inline-block;
                    }

                    input {
                        appearance: none;
                        -moz-appearance: none;
                        position: relative;
                        outline: none;
                        background: var(--background);
                        border: none;
                        margin: 0;
                        transform: translateY(5px);
                        padding: 0;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: box-shadow 0.3s;
                        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                        &:hover {
                            --s: 2px;
                            --b: var(--border-hover);
                        }

                        &:checked {
                            --b: var(--border-active);
                        }
                    }

                    svg {
                        pointer-events: none;
                        fill: none;
                        stroke-width: 2px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke: var(--stroke, var(--border-active));
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 21px;
                        height: 21px;
                        transform: scale(var(--scale, 1)) translateZ(1);
                        margin-top: 0rem;
                    }

                    span {
                        transform: translateY(-5px);
                        display: inline;
                        margin-left: 0.5rem;
                    }

                    &.path {
                        input {
                            &:checked {
                                --s: 2px;
                                transition-delay: 0.4s;

                                &+svg {
                                    --a: 16.1 86.12;
                                    --o: 102.22;
                                }
                            }
                        }

                        svg {
                            stroke-dasharray: var(--a, 86.12);
                            stroke-dashoffset: var(--o, 86.12);
                            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                        }
                    }
                }
            }
        }

        .multiselect {
            width: 100%;
        }
    }

    .blocos-textos {
        margin: 1rem 0rem;
    }

    .bt-salvar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
            p {
                margin: 0;
            }
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .bt-salvar-tab {
        text-align: center;
        margin: 1rem;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .bt-excluir {
        img {
            cursor: pointer;
            width: 23px;
            padding: 0.2rem;
            height: 1.4rem;
            display: inline-block;
        }

        button {
            border-radius: 1rem;
            border: none;
        }
    }

    .spinner-loading {
        visibility: hidden;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0rem .5rem 0rem -0.5rem;
    }

    #botao-salvar:hover {
        background-color: #4CAF50;
    }

    #botao-salvar-tab:hover {
        background-color: #4CAF50;
    }
}
</style> 