import { render, staticRenderFns } from "./ExibirStatusMandato.vue?vue&type=template&id=6a72b8f8&scoped=true"
import script from "./ExibirStatusMandato.vue?vue&type=script&lang=js"
export * from "./ExibirStatusMandato.vue?vue&type=script&lang=js"
import style0 from "./ExibirStatusMandato.vue?vue&type=style&index=0&id=6a72b8f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a72b8f8",
  null
  
)

export default component.exports