<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
    <table>
      <thead>
        <tr>
          <th>Órgão</th> 
          <th>Tipo</th> 
          <th>Nome</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="colegiado in colegiadoFormat">
          <template>
            <tr :key="colegiado.id">
              <td data-label="Orgão:" style="text-align: left;">
                <span>{{ colegiado.orgao_id }}</span>
              </td>
              <td data-label="Tipo:" style="text-align: left;">
                <span>{{ formatarTipo(colegiado.tipo) }}</span>
              </td>
              <td data-label="Nome:">
                <span>{{ colegiado.nome }}</span>
              </td>
              <td data-label="Status:" style="text-align: left;">
                <span>{{ formatarStatus(colegiado.status) }}</span>
              </td>
              <td class="coluna-acoes-tabela">
                <!-- Visualizar -->
              <div class="icone-tabela">
                  <router-link :to="{
                    name: 'ColegiadoShow',
                    params: { id: colegiado.id },
                  }">
                    <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                  </router-link>
              </div>
                <!-- Editar -->
                <div class="icone-tabela">
                  <router-link :to="{
                    name: 'ColegiadoEdit',
                    params: { id: colegiado.id },
                  }">
                    <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar" />
                  </router-link>
                </div>
                <!-- Excluir -->
                <span class="icone-tabela" type="button" @click="opcoesExcluir(colegiado.id)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir" />
                </span>
              </td>
              <div class="modal-exclusao" :id="colegiado.id">
                <div class="modal-mask">
                  <div class="modal-container">
                    <div class="modal-header">
                      <h2>Deseja mesmo excluir esse Colegiado?</h2>
                    </div>
                    <div class="modal-body">
                      <p>Dados do Colegiado:</p>
                      <div class="dados">
                        <div class="flexbox">
                          <div class="texto-centralizado">
                            Nome: <span>{{ colegiado.nome }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Órgão: <span>{{ colegiado.orgao_id }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Tipo: <span>{{ formatarTipo(colegiado.tipo) }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Status: <span>{{ formatarStatus(colegiado.status) }}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                            <div class="texto-centralizado">
                                Quantidade de membros titulares mínimo: <span>{{ colegiado.quantidade_membros_titulares_minimo }}</span>
                            </div>
                            <div class="texto-centralizado">
                                Quantidade de membros suplentes mínimo: <span>{{ colegiado.quantidade_membros_suplentes_minimo }}</span>
                            </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                                Quantidade de membros titulares máximo: <span>{{ colegiado.quantidade_membros_titulares_maximo }}</span>
                            </div>
                            <div class="texto-centralizado">
                                Quantidade de membros suplentes máximo: <span>{{ colegiado.quantidade_membros_suplentes_maximo }}</span>
                            </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                            Regimento Interno: <span>{{ colegiado.regimento_interno}}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                              Motivo do Status Inativo:
                              <span v-if="colegiado.status === 'inativo'">{{ colegiado.status_motivo }}</span>
                              <span v-else>Não disponível</span>
                          </div>
                          <div class="texto-centralizado">
                              Link do Motivo do Status Inativo:
                              <span v-if="colegiado.status === 'inativo'">{{ colegiado.status_link_motivo || 'Não disponível' }}</span>
                              <span v-else>Não disponível</span>
                          </div>
                        </div>
                        <div class="flexbox">
                            <div class="texto-centralizado">
                              Duração do mandato (anos): <span>{{ colegiado.duracao_mandato }}</span>
                            </div>
                            <div class="texto-centralizado">
                              Intervalo do mandato (anos): <span>{{ colegiado.intervalo_mandato }}</span>
                            </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                            Remunerado: <span>{{ colegiado.remunerado ? 'Sim' : 'Não' }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Valor Remunerado:
                            <span v-if="colegiado.valor_remunerado">{{ colegiado.valor_remunerado }}</span>
                            <span v-else>Não aplicado</span>
                          </div>
                          <div class="texto-centralizado">
                            Referendar Nome: <span>{{ colegiado.referendar_nome ? 'Sim' : 'Não' }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Sabatina Alego: <span>{{ colegiado.sabatina_alego ? 'Sim' : 'Não' }}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                            Nomeação do Governador: <span>{{ colegiado.nomeacao_governador ? 'Sim' : 'Não' }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Cargo de presidente nomeação em comissão: <span>{{ colegiado.cargo_presidente ? 'Sim' : 'Não' }}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado ">
                            Lista Tríplice: <span>{{ colegiado.lista_triplice ? 'Sim' : 'Não' }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Indicação do Governador: <span>{{ colegiado.indicacao_governador ? 'Sim' : 'Não' }}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado ">
                            Permite Recondução: <span>{{ colegiado.permite_reconducao ? 'Sim' : 'Não' }}</span>
                          </div>
                          <div class="texto-centralizado">
                            Quantidade de recondução:
                              <span v-if="colegiado.permite_reconducao">{{ colegiado.quantidade_reconducao }}</span>
                              <span v-else>Não aplicado</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado ">
                            Legislação: <span>{{ colegiado.legislacao}}</span>
                          </div>
                          <div class="texto-centralizado">
                            Link Legislação:
                              <span v-if="colegiado.link_legislacao">{{ colegiado.link_legislacao }}</span>
                              <span v-else>Não disponível</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado ">
                            Representantes da sociedade civil organizada: <span>{{ colegiado.representantes_sociedade}}</span>
                          </div>
                        </div>
                        <div class="flexbox">
                          <div class="texto-centralizado">
                            Observação: <span>{{ colegiado.observacao}}</span>
                          </div>
                        </div>
                      </div>
                  </div>
                    <div class="modal-footer">
                      <button class="btn-confirmar-exclusao" @click="excluirLinha(colegiado.id)">Sim</button>
                      <button class="btn-cancelar-exclusao" @click="opcoesExcluir(colegiado.id)">Não</button>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </template>
        </template>

        <tr v-if="!colegiados || colegiados.length === 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns";
  import MsgErroFormularios from "../MsgErroFormularios";
  import ColegiadoApi from "../../services/ColegiadoApi";

  export default {
    name: "TabelaResultadosColegiado",
    props: {
      colegiados: Array,
      colegiado: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.colegiadoApi = new ColegiadoApi();
    },
    data() {
      return {
        msgErros: {
          colegiado: "",
        },
        erro: false,
        colegiadoFormat: this.colegiados.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      opcoesExcluir(colegiadoId) {
        let modalExclusao = document.getElementsByClassName("modal-exclusao");

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == colegiadoId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible";
            } else {
              modalExclusao[i].style.visibility = "hidden";
            }
          }
        }
      },
      formatarStatus(valor) {
          if (valor === 'em_estruturacao') {
              return 'Em estruturação';
          } else if (valor === 'ativo') {
              return 'Ativo';
          } else if (valor === 'inativo') {
              return 'Inativo';
          }

          return valor; 
      },
      formatarTipo(valor) {
        if (valor === 'conselho') {
          return 'Conselho';
        } else if (valor === 'comite') {
          return 'Comitê';
        } else if (valor === 'forum') {
          return 'Fórum';
        } else if (valor === 'diretoria') {
          return 'Diretoria';
        } else if (valor === 'camara') {
          return 'Câmara';
        }else if (valor === 'colegio') {
          return 'Colégio';
        }else if (valor === 'junta') {
          return 'Junta';
        }

        return valor; 
      },

      async excluirLinha(colegiadoId) {
        this.opcoesExcluir(colegiadoId);

        try {
          this.carregando = true;
          this.erro = false;

          if (this.colegiadoApi.cancelTokens.destroyColegiado) {
            this.colegiadoApi.cancelTokens.destroyColegiado.cancel();
          }

          await this.colegiadoApi.destroyColegiado(colegiadoId);
          window.location.reload();
        } catch (err) {
          if (this.colegiadoApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.colegiado =
            "Não foi possível excluir este colegiado, pois ele está vinculado a alguma outra entidade.";
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .flexbox {
      display: flex;
      gap: 1.5rem;
      margin-bottom: 1rem;
    }
    .modal-mask {
      min-width: 50vw;
    }
    .resultados {
      width: 100%;
      height: max-content;
      margin: 0;

      table {
        border-collapse: collapse;
        width: 100%;

        a {
          cursor: pointer;
          color: #375a3a;
        }

        thead {
          border-bottom: solid #484344 1px;
          color: #0a4a22;

          th {
            padding: .5rem .7rem;
            text-align: center;
            background-color: #edf2ee;
            border-right: 1px solid #484344;
            font-size: .9rem;
            font-weight: bold;
          }

          th:last-child {
            border-right: none;
          }
        }

        tbody {
          tr {
            margin: 1rem;

            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 12rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: justify;
              max-width: 50rem;
              text-align: center;
            }
          }

          tr:nth-child(4n + 1),
          tr:nth-child(4n + 2) {
            text-align: center;
          }

          .coluna-acoes-tabela {
            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .1rem 0 .1rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            img {
              height: .9rem;
            }

            button {
              border: none;
              background-color: #fff;
            }
          }

          .sem-resultados {
            background-color: transparent !important;
            text-align: center;
            height: 5rem;
            color: rgb(73, 73, 73);
            font-style: oblique;
          }

          .modal-exclusao {
            visibility: hidden;
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            display: table;
            transition: opacity 0.3s ease;

            .modal-mask {
              border-radius: 1rem;
              position: relative;
              z-index: 1000;
              top: 3vh;
              width: calc(85vw - 10rem);
              height: fit-content;
              background-color: #f2f2f2;
              transition: opacity 0.3s ease;
              margin: 0 auto;
            }

            .modal-container {
              height: 100%;
              padding: .7rem;
              position: relative;
              overflow-y: auto;
              overflow-x: auto;
              font-family: "Montserrat-Medium";

              .modal-header {
                height: fit-content;
                width: fit-content;
                margin: .5rem auto .3rem auto;

                h2 {
                  font-size: 1.3rem;
                  font-weight: bold;
                }
              }

              .modal-body {
                border: 1px solid gray;
                border-radius: 1rem;
                text-align: -webkit-center;

                p {
                  font-size: 1rem;
                  font-weight: bold;
                  margin-top: .3rem;
                  margin-bottom: .5rem;
                  margin-left: 1rem;
                  color: #005516;
                }

                .dados {
                  font-weight: bold;
                  font-size: 1rem;
                  text-align-last: left;
                  margin: .5rem;

                  span {
                    font-weight: normal;
                    font-size: .9rem;
                    margin-left: .3rem;
                  }

                  .flexbox-exclusao {
                    display: flex;
                    justify-content: space-between;
                  }
                }
              }

              .modal-footer {
                margin: .7rem auto 0rem auto;
                place-content: space-evenly;
                font-size: 1rem;

                .btn-confirmar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #005516;
                  color: #f2f2f2;
                }

                .btn-confirmar-exclusao:hover {
                  background-color: #005517d0;
                  color: #f2f2f2;
                }

                .btn-cancelar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #bd0000;
                  color: #f2f2f2;
                }

                .btn-cancelar-exclusao:hover {
                  background-color: #bd0000d7;
                  color: #f2f2f2;
                }
              }
            }
          }
        }
      }

      td {
        img {
          height: 1.5rem;
          margin: 0rem 0.5rem 0rem 0.5rem;
        }
      }
    }
  }
  @media screen and (min-width: "570px") and (max-width: "1065px") {
    .modal-mask {
      min-width: 60vw;
    }

    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }

    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;

          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;

            .icone-tabela {
              margin: 0rem calc(22vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .9rem;
              margin: 0;
            }

            button {
              border: none;
            }
          }
        }

        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }

          td:nth-child(2) {
            text-align: left !important;
          }

          td:nth-child(3) {
            text-align: left !important;
          }

          td:nth-child(4) {
            text-align: left !important;
          }

          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }

        .sem-resultados {
          padding-top: 5rem;
        }

        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }

          span {
            font-size: .9rem;
          }
        }

        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }

        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }

          span {
            font-size: 1rem;
            display: block;
          }
        }

        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 20vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }

          .modal-container {
            height: 100%;
            padding: .7rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.3rem;
                font-weight: bold;
              }
            }

            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .5rem;
                margin-left: 1rem;
                color: #005516;
              }

              .dados {
                font-weight: bold;
                font-size: 1rem;
                text-align: initial;
                margin: 0 0 .5rem 1rem;

                span {
                  font-weight: normal;
                  font-size: .9rem;
                  margin-left: .3rem;
                }
              }
            }

            .modal-footer {
              margin: .7rem auto 0rem auto;
              place-content: space-evenly;
              font-size: 1rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #005516;
                color: #f2f2f2;
              }

              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "569px") {
    .modal-mask {
      min-width: 70vw;
    }

    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }

    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;

          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;

            .icone-tabela {
              margin: 0rem calc(18vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .6rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .8rem;
              margin: 0;
            }

            button {
              border: none;
            }
          }
        }

        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }

          td:nth-child(2) {
            text-align: left !important;
          }

          td:nth-child(3) {
            text-align: left !important;
          }

          td:nth-child(4) {
            text-align: left !important;
          }

          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }

        .sem-resultados {
          padding-top: 5rem;
        }

        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }

          span {
            font-size: .9rem;
          }
        }

        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }

        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }

          span {
            font-size: 1rem;
            display: block;
          }
        }

        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 30vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }

          .modal-container {
            height: 100%;
            padding: .3rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.1rem;
                font-weight: bold;
              }
            }

            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .2rem;
                margin-left: 1rem;
                color: #005516;
              }

              .dados {
                font-weight: bold;
                font-size: .9rem;
                text-align: initial;
                margin: 0 0 .5rem .3rem;

                span {
                  font-weight: normal;
                  font-size: .8rem;
                  margin-left: .3rem;
                }
              }
            }

            .modal-footer {
              margin: .5rem auto 0rem auto;
              place-content: space-evenly;
              font-size: .9rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #005516;
                color: #f2f2f2;
              }

              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
</style>