<template>
    <div>
      <ColegiadoForm :colegiado="null" />
    </div>
  </template>
  
  <script>
  import ColegiadoForm from "../../components/colegiado/ColegiadoForm.vue";
  
  export default {
    name: "ColegiadoNew",
    data() {
      return {};
    },
    components: {
      ColegiadoForm,
    },
  };
  </script>
  
<style lang="scss" scoped></style>
  