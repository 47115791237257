import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class HomeApi {
  cancelTokens = {
    getCessoes: null,
    getCessao: null,
    getOrgaos: null,
    getOrgao: null,
    //getDisposicoesData: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCessoes({
    page = 1,
    qtd_por_pagina,
    servidor_id,
    data_inicio,
    data_termino,
    cessoes,
  }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      servidor_id,
      data_inicio,
      data_termino,
      cessoes,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessoes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessoes = null;
    return data;
  }

  async getColegiado(colegiadoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getColegiado = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${colegiadoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getColegiado = null;

    return data;
  }

  async getColegiados(){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getColegiados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getColegiados = null;
    return data;
  }

}

export default HomeApi;
