import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class TipoMandatoApi {
  cancelTokens = {
    getTiposMandato: null,
    getTipoMandato: null,
    setTipoMandato: null,
    destroyTipoMandato: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getTiposMandato({ page = 1, qtd_por_pagina, nome, ativo, codigo }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      ativo,
      codigo,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTiposMandato = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_mandatos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTiposMandato = null;
    return data;
  }

  async getTipoMandato(tipoMandatoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipoMandato = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_mandatos/${tipoMandatoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipoMandato = null;

    return data;
  }

  async destroyTipoMandato(tipoMandatoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyTipoMandato = source;

    ("Chamando a API para excluir o tipo de mandato com o ID:", tipoMandatoId);

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_mandatos/${tipoMandatoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyTipoMandato = null;
    return data;
  }
  async setTipoMandato(tipoMandato) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.setTipoMandato = source;
  
    let url;
    let method;
    let requestData;
  
    if (tipoMandato.id) {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_mandatos/${tipoMandato.id}`;
      method = 'put';
      requestData = {
        tipo_mandato: { // Encapsula os dados em um objeto tipo_mandato
          nome: tipoMandato.nome,
          ativo: tipoMandato.ativo,
          codigo: tipoMandato.codigo
        }
      };
    } else {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/tipos_mandatos`;
      method = 'post';
      requestData = {
        tipo_mandato: { // Encapsula os dados em um objeto tipo_mandato
          nome: tipoMandato.nome,
          ativo: tipoMandato.ativo,
          codigo: tipoMandato.codigo
        }
      };
    }
    try {
      const { data } = await axiosInstance({
        method: method,
        url: url,
        data: requestData,
        cancelToken: source.token
      });
  
      this.cancelTokens.setTipoMandato = null;
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
}

export default TipoMandatoApi;
