<template>
  <div id="app">
    <BannerDeAmbiente :ambienteAtual="ambienteAtual" />
    <Menu v-if="!naoExibirMenu"></Menu>
    <router-view></router-view>

    <div v-if="!naoExibirBotoes">
      <!-- Botão para rolagem direto para cima -->
      <div v-if="isTop" @click="scrollUp" class="back-to-top-btn go-top">
        <i>↑</i>
      </div>
      <!-- Botão para rolagem direto para baixo -->
      <div v-if="isBottom" @click="scrollDown" class="back-to-bottom-btn go-bottom">
        <i>↓</i>
      </div>
    </div>

  </div>
</template>

<script>
  import Vue from "vue";
  import VueRouter from "vue-router";
  import VueMeta from "vue-meta";
  import VueSweetalert2 from "vue-sweetalert2";
  import VueTheMask from 'vue-the-mask';

  import Menu from "./components/Menu";
  import BannerDeAmbiente from './components/BannerDeAmbiente';
  import router from "./router";

  const originalPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
      if (err && err.name !== "NavigationDuplicated") return err;
    });
  };

  Vue.use(VueRouter);
  Vue.use(VueMeta);
  Vue.use(VueSweetalert2);
  Vue.use(VueTheMask);

  export default {
    name: "App",
    router,
    data() {
      const rotasSemMenu = ["Autenticar", "RecuperarSenha", "TelaAvisoEmail", "RedefinirSenha", "NaoEncontrado"];
      const rotasSemBotoes = ["Autenticar", "RecuperarSenha", "TelaAvisoEmail", "RedefinirSenha", "NaoEncontrado"];
      const ambienteAtual = process.env.VUE_APP_ENV;
      return {
        rotasSemMenu,
        rotasSemBotoes,
        naoExibirMenu: rotasSemMenu.includes(this.$router.currentRoute.name),
        naoExibirBotoes: rotasSemMenu.includes(this.$router.currentRoute.name),
        isTop: false,
        isBottom: false,
        ambienteAtual,
      };
    },
    methods: {
      handleScroll() {
        this.checkScrollButtons();
      },
      checkScrollButtons() {
        const scrollPosition = window.scrollY;
        const contentHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;

        this.isTop = scrollPosition > 0;
        this.isBottom = scrollPosition < contentHeight - windowHeight
      },
      scrollUp() {
        window.scrollBy(0, -window.innerHeight); // Rola para cima uma vez o tamanho da janela
      },
      scrollDown() {
        window.scrollBy(0, window.innerHeight); // Rola para baixo uma vez o tamanho da janela
      },
    },
    components: {
      Menu,
      BannerDeAmbiente,
    },
    watch: {
      $route: function (to) {
        this.naoExibirMenu = this.rotasSemMenu.includes(to.name);
        this.naoExibirBotoes = this.rotasSemMenu.includes(to.name);
        this.isTop = false;
        this.isBottom = false;
      },
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
      this.checkScrollButtons();
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  };
</script>

<style lang="scss" scoped>
  .back-to-top-btn {
    position: fixed;
    cursor: pointer;
    right: 20px;
    bottom: 60px;
    color: #fff;
    background-color: #139b2b;
    z-index: 4;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 22px;
    transition: .6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
      position: absolute;
      right: 0;
      left: 0;
      top: 43%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 23px;
      font-style: normal;
      margin-left: auto;
      margin-right: auto;
    }

    &.go-top {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &:hover {
      background-color: #017015;
      color: #fff;
      transition: .6s;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      transform: translateY(-5px);
    }
  }
  .back-to-bottom-btn {
    position: fixed;
    cursor: pointer;
    right: 20px;
    bottom: 15px;
    /* Ajuste a distância entre os botões aqui */
    color: #fff;
    background-color: #139b2b;
    z-index: 4;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 22px;
    transition: .6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
      position: absolute;
      right: 0;
      left: 0;
      top: 43%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 23px;
      font-style: normal;
      margin-left: auto;
      margin-right: auto;
    }

    &.go-bottom {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &:hover {
      background-color: #017015;
      color: #fff;
      transition: .6s;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      transform: translateY(5px);
    }
  }
</style>
