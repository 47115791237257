import Home from "../views/home/Home"


import PainelBI from "../views/paineis_bi/PainelBI";

import Colegiado from "../views/colegiado/Colegiado";
import ColegiadoNew from "../views/colegiado/ColegiadoNew";
import ColegiadoShow from "../views/colegiado/ColegiadoShow";
import ColegiadoEdit from "../views/colegiado/ColegiadoEdit";


import Membro from "../views/membro/Membro.vue";
import MembroNew from "../views/membro/MembroNew.vue";
import MembroShow from "../views/membro/MembroShow.vue";
import MembroEdit from "../views/membro/MembroEdit.vue";

import CategoriaRepresentativa from "../views/categoria_representativa/CategoriaRepresentiva.vue";
import CategoriaRepresentativaEdit from "../views/categoria_representativa/CategoriaRepresentivaEdit.vue";
import CategoriaRepresentativaNew from "../views/categoria_representativa/CategoriaRepresentivaNew.vue";
import CategoriaRepresentativaShow from "../views/categoria_representativa/CategoriaRepresentivaShow.vue";

import StatusMandato from "../views/status_mandato/StatusMandato.vue";
import StatusMandatoEdit from "../views/status_mandato/StatusMandatoEdit.vue";
import StatusMandatoNew from "../views/status_mandato/StatusMandatoNew.vue";
import StatusMandatoShow from "../views/status_mandato/StatusMandatoShow.vue";

import TipoMandato from "../views/tipo_mandato/TipoMandato.vue";
import TipoMandatoEdit from "../views/tipo_mandato/TipoMandatoEdit.vue";
import TipoMandatoNew from "../views/tipo_mandato/TipoMandatoNew.vue";
import TipoMandatoShow from "../views/tipo_mandato/TipoMandatoShow.vue";



import NaoEncontrado from "../views/NaoEncontrado";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

export default [
  {
    name: "Home",
    path: "/home",
    component: Home,
    meta: {
      raiz: true,
      requerPermissao: true,
      permissoes: ["home"],
      label: "Home",      
    },
  },
  {
    name: "Colegiados", 
    path: "/colegiados",
    component: Colegiado,
    meta: {
      raiz: true,
      requerPermissao: true,
      permissoes: ["colegiados"],
      label: "Colegiados",
    },
  },
  {
    name: "ColegiadoNew",
    path: "/colegiados/colegiado-new",
    component: ColegiadoNew,
    meta: {
      requerPermissao: true,
      permissoes: ["colegiados"],
    },
  },
  {
    name: "ColegiadoShow",
    path: "/colegiados/colegiado-show/:id",
    component: ColegiadoShow,
    meta: {
      requerPermissao: true,
      permissoes: ["colegiados"],
    },
  },
  {
    name: "ColegiadoEdit",
    path: "/colegiados/colegiado-edit/:id",
    component: ColegiadoEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["colegiados"],
    },
  },
    {
      name: "Membros",
      path: "/membros",
      component: Membro, 
      meta: {
        raiz: true,
        requerPermissao: true,
        permissoes: ["membros"],
        label: "Membros",
      },
    },
    {
      name: "MembroNew",
      path: "/membros/membro-new",
      component: MembroNew, 
      meta: {
        requerPermissao: true,
        permissoes: ["membros"],
      },
    },
    {
      name: "MembroShow",
      path: "/membros/membro-show/:id",
      component: MembroShow, 
      meta: {
        requerPermissao: true,
        permissoes: ["membros"],
      },
    },
    {
      name: "MembroEdit",
      path: "/membros/membro-edit/:id",
      component: MembroEdit,
      meta: {
        requerPermissao: true,
        permissoes: ["membros"],
      },
    }, 
    {
      name: "CategoriaRepresentativa",
      path: "/entidades-representativas",
      component: CategoriaRepresentativa,
      meta: {
        raiz: true,
        requerPermissao: true,
        permissoes: ["categorias_representativas"],
        label: "Entidades Representativas",
      },
    },
    {
      name: "CategoriaRepresentativaNew",
      path: "/entidades-representativas/new",
      component: CategoriaRepresentativaNew,
      meta: {
        requerPermissao: true,
        permissoes: ["categorias_representativas"],
      },
    },
    {
      name: "CategoriaRepresentativaShow",
      path: "/entidades-representativas/:id",
      component: CategoriaRepresentativaShow,
      meta: {
        requerPermissao: true,
        permissoes: ["categorias_representativas"],
      },
    },
    {
      name: "CategoriaRepresentativaEdit",
      path: "/entidades-representativas/:id/edit",
      component: CategoriaRepresentativaEdit,
      meta: {
        requerPermissao: true,
        permissoes: ["categorias_representativas"],
      },
    },    
    {
      name: "StatusMandato",
      path: "/status-mandato",
      component: StatusMandato,
      meta: {
        raiz: true,
        requerPermissao: true,
        permissoes: ["status_mandatos"],
        label: "Status de Mandatos",
      },
    },
    {
      name: "StatusMandatoNew",
      path: "/status-mandato/new",
      component: StatusMandatoNew,
      meta: {
        requerPermissao: true,
        permissoes: ["status_mandatos"],
      },
    },
    {
      name: "StatusMandatoShow",
      path: "/status-mandato/:id",
      component: StatusMandatoShow,
      meta: {
        requerPermissao: true,
        permissoes: ["status_mandatos"],
      },
    },
    {
      name: "StatusMandatoEdit",
      path: "/status-mandato/:id/edit",
      component: StatusMandatoEdit,
      meta: {
        requerPermissao: true,
        permissoes: ["status_mandatos"],
      },
    },

    {
      name: "TipoMandato",
      path: "/tipo-mandato",
      component: TipoMandato,
      meta: {
        raiz: true,
        requerPermissao: true,
        permissoes: ["tipos_mandatos"],
        label: "Tipos de Mandatos",
      },
    },
    {
      name: "TipoMandatoNew",
      path: "/tipo-mandato/new",
      component: TipoMandatoNew,
      meta: {
        requerPermissao: true,
        permissoes: ["tipos_mandatos"],
      },
    },
    {
      name: "TipoMandatoShow",
      path: "/tipo-mandato/:id",
      component: TipoMandatoShow,
      meta: {
        requerPermissao: true,
        permissoes: ["tipos_mandatos"],
      },
    },
    {
      name: "TipoMandatoEdit",
      path: "/tipo-mandato/:id/edit",
      component: TipoMandatoEdit,
      meta: {
        requerPermissao: true,
        permissoes: ["tipos_mandatos"],
      },
    },
    {
      name: "PainelBI",
      path: "/paineis_bi",
      component: PainelBI,
      meta: {
        raiz: true,      
        requerPermissao: true,
        permissoes: ["painel_bi"],
        label: "Painéis BI",      
      },
    },
    
  {
    name: "NaoEncontrado",
    path: "/nao-encontrado",
    component: NaoEncontrado,
  },
  {
    path: "*",
    redirect: (to) => {
      return { name: "NaoEncontrado", query: { url: to.path } };
    },
  },
];
