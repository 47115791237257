import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class CategoriaRepresentativaApi {
  cancelTokens = {
    getCategoriasRepresentativas: null,
    getCategoriaRepresentativa: null,
    setCategoriaRepresentativa: null,
    destroyCategoriaRepresentativa: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCategoriasRepresentativas({ page = 1, qtd_por_pagina, nome, ativo }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      ativo,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoriasRepresentativas = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_representativas?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoriasRepresentativas = null;
    return data;
  }

  async getCategoriaRepresentativa(categoriaRepresentativaId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoriaRepresentativa = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_representativas/${categoriaRepresentativaId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoriaRepresentativa = null;

    return data;
  }

  async destroyCategoriaRepresentativa(categoriaRepresentativaId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyCategoriaRepresentativa = source;

    ("Chamando a API para excluir a categoria com o ID:", categoriaRepresentativaId);

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_representativas/${categoriaRepresentativaId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyCategoriaRepresentativa = null;
    return data;
  }

  async setCategoriaRepresentativa(categoriaRepresentativa) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.setCategoriaRepresentativa = source;
  
    if (categoriaRepresentativa.id) {
        const { data } = await axiosInstance.put(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_representativas/${categoriaRepresentativa.id}`,
            {
                cancelToken: source.token,
                categoria_representativa: {
                    nome: categoriaRepresentativa.nome,
                    ativo: categoriaRepresentativa.ativo,
                },
            },
        );
  
        this.cancelTokens.setCategoriaRepresentativa = null;
        return data;
    } else {
        const { data } = await axiosInstance.post(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_representativas`,
            {
                cancelToken: source.token,
                categoria_representativa: {
                    nome: categoriaRepresentativa.nome,
                    ativo: categoriaRepresentativa.ativo,
                },
            },
        );
  
        this.cancelTokens.setCategoriaRepresentativa = null;
        return data;
    }
}

}  

export default CategoriaRepresentativaApi;
