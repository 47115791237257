<template>
    <div>
      <transition name="modal" v-if="carregando">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
              <label>Aguarde. Carregando.</label>
            </div>
          </div>
        </div>
      </transition>
      <div v-else>
        <div v-if="erro">
          <ErroResultados></ErroResultados>
        </div>
        <template v-else>
          <ExibirTipoMandato v-if="tipoMandato" :tipoMandato="tipoMandato" />
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import TipoMandatoApi from "../../services/TipoMandatoApi";
  import ErroResultados from "../../components/ErroResultados.vue";
  import ExibirTipoMandato from "../../components/tipo_mandato/ExibirTipoMandato.vue";
  import metaDados from "../../helpers/metaDados";
  
  export default {
    name: "TipoMandatoShow",
    metaInfo() {
      if (!this.tipoMandato) {
        return {};
      }
  
      const title = this.tipoMandato.nome;
      const description = this.tipoMandato.nome;
  
      return {
        title,
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content: description,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: `${title} - Casa Civil do Estado de Goiás`,
          },
          {
            vmid: "twitter:description",
            name: "twitter:description",
            property: "twitter:description",
            content: description,
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            property: "twitter:title",
            content: `${title} - Casa Civil do Estado de Goiás`,
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        carregando: true,
        erro: false,
        tipoMandato: null,
      };
    },
    components: {
      ErroResultados,
      ExibirTipoMandato,
    },
    created() {
      this.tipoMandatoApi = new TipoMandatoApi();
      this.carregarTipoMandato();
    },
    watch: {
      $route: {
        handler(to, from) {
          if (to.params.id != from.params.id) {
            this.carregarTipoMandato();
          }
        },
        deep: true,
      },
    },
    methods: {
      async carregarTipoMandato() {
        try {
          this.carregando = true;
          this.erro = false;
  
          if (this.tipoMandatoApi.cancelTokens.getTipoMandato) {
            this.tipoMandatoApi.cancelTokens.getTipoMandato.cancel();
          }
  
          this.tipoMandato = await this.tipoMandatoApi.getTipoMandato(
            this.$route.params.id
          );
  
          this.carregando = false;
        } catch (err) {
          if (this.tipoMandatoApi.isCancel(err)) {
            return;
          }
          console.log(err);
  
          this.erro = true;
          this.carregando = false;
          this.tipoMandato = null;
        }
      },
    },
  };
  </script>
  
<style lang="scss" scoped>
  .carregando-show {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100vh;
  }
  
  .erro-carregando {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100vh;
  }
  
  .modal-mask {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: table;
      transition: opacity 0.3s ease;
  }
  
  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }
  
  .modal-container {
      width: fit-content;
      height: 0px auto;
      margin: 0px auto;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      font-size: 1.5rem;
      font-family: "Montserrat-Medium";
  
      img {
          width: 2rem;
          height: 2rem;
          margin-right: 1.6rem;
      }
  
      label {
          font-size: 1.3rem;
          color: rgba(255, 255, 255, 0.556);
          display: inline;
          position: relative;
          top: 0rem;
      }
  }
</style>   