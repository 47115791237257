<template>
    <div>
      <CategoriaRepresentativaForm :categoriaRepresentativa="null" />
    </div>
  </template>
<script>
import CategoriaRepresentativaForm from "../../components/categoria_representativa/CategoriaRepresentativaForm.vue";

export default {
    name: "CategoriaRepresentativaNew",
    components: {
        CategoriaRepresentativaForm,
    },
};
</script>
<style lang="scss" scoped></style>
