import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");
const headers = { "Content-Type": "multipart/form-data" };

class ColegiadoApi {
  cancelTokens = {
    getColegiados: null,
    getColegiado: null,
    getOrgao: null,
    getOrgaos:null,
    downloadAnexo: null,
    setColegiado: null,
    setColegiadoFormData: null,
    setColegiadoFormDataDestoy: null,
    destroyColegiado: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }
  
  async getColegiados({ page = 1, qtd_por_pagina, 
    orgao_id,
    tipo,
    nome,
    quantidade_membros_titulares_minimo,
    quantidade_membros_titulares_maximo,
    quantidade_membros_suplentes_minimo,
    quantidade_membros_suplentes_maximo,
    status,
    status_motivo,
    status_link_motivo,
    duracao_mandato,
    intervalo_mandato,
    observacao,
    sabatina_alego, 
    remunerado, 
    valor_remunerado, 
    regimento_interno,
    link_regimento_interno,
    permite_reconducao,
    quantidade_reconducao,
    referendar_nome, 
    nomeacao_governador, 
    cargo_presidente,
    lista_triplice,
    indicacao_governador,
    representantes_sociedade  }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      orgao_id,
      tipo,
      nome,
      quantidade_membros_titulares_minimo,
      quantidade_membros_titulares_maximo,
      quantidade_membros_suplentes_minimo,
      quantidade_membros_suplentes_maximo,
      status,
      status_motivo,
      status_link_motivo,
      duracao_mandato,
      intervalo_mandato,
      sabatina_alego,
      remunerado,
      valor_remunerado,
      regimento_interno,
      link_regimento_interno,
      referendar_nome,
      permite_reconducao,
      quantidade_reconducao,
      nomeacao_governador,
      cargo_presidente,
      observacao,
      lista_triplice,
      indicacao_governador,
      representantes_sociedade,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getColegiados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getColegiados = null;
    return data;
  }

  async getColegiado(colegiadoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getColegiado = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${colegiadoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getColegiado = null;

    return data;
  }

  async getOrgao(){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/orgao_origem`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgao = null;

    return data;
  }

  async getOrgaos(orgao_id){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/getOrgao`,
      {
        params: {
          orgao_id: orgao_id,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaos = null;

    return data;
  }

  async destroyColegiado(colegiadoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyColegiado = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${colegiadoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyColegiado = null;
    return data;
  }
  
  async setColegiado(colegiado) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.setColegiado = source;
  
    if (colegiado.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${colegiado.id}`,
        {
          cancelToken: source.token,
          ...colegiado,
        },
      /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setColegiado = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados`,
        {
          cancelToken: source.token,
          ...colegiado,
        },
        //
      );

      this.cancelTokens.setColegiado = null;
      return data;
    }
  }

  async setColegiadoFormData(dataa, colegiadoFormm, aux, aux1) {
    let formData = new FormData();
    const countaux = aux;
    var countaux1 = aux1;

    for(var j=countaux1; j<countaux; j++){
      const aux3 = j;
      if(colegiadoFormm.anexos_attributes[aux3].aux != null){
        formData.append('anexos_attributes['+aux3+']titulo', colegiadoFormm.anexos_attributes[aux3].titulo);
        formData.append('anexos_attributes['+aux3+']arquivo', colegiadoFormm.anexos_attributes[aux3].arquivo);
      }
    }

    const { data } = await axiosInstance.put(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${dataa}`, formData, headers);
    
    return data;
  }

  async setColegiadoFormDataDestoy(dataa, colegiadoFormm, aux1) {
    let formData = new FormData();
    const countaux1 = aux1;

    for(var k=0; k<countaux1; k++){
      const aux4 = k;
      if(colegiadoFormm.anexos_attributes[aux4]._destroy){
        formData.append('anexos_attributes['+aux4+']id', colegiadoFormm.anexos_attributes[aux4].id);
        formData.append('anexos_attributes['+aux4+']_destroy', "true");
      }
    }

    const { data } = await axiosInstance.put(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${dataa}`, formData, headers);
    
    return data;
  }
}

export default ColegiadoApi;