<template>
    <div class="wrapper" v-if="this.colegiado">
      <div class="topo-header centro">
        <botao-voltar class="voltar" nomeRota="Colegiado" />
        <h1>Visualizar Colegiado</h1>
      </div>
      <tabs>
        <tab class="tab-dados-basicos" title="Informações Gerais">     
          <div class="form centro">
            <fieldset>
              <div class="caixa-form item">
                <div class="flexbox" v-if="colegiado.nome || colegiado.orgao">
                  <div class="item" v-if="colegiado.nome">
                    <label><b>Nome:</b></label>
                    <p>{{ colegiado.nome }}</p>                    
                  </div>
                  <div class="item v-select" v-if="colegiado.orgao">
                    <label><b>Órgão:</b></label>
                    <p>{{ colegiado.orgao }}</p>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.tipo || colegiado.status || colegiado.nomeacao_governador">
                  <div class="item" v-if="colegiado.tipo">
                    <div class="radio">
                      <label class="radio-label"><b>Tipo:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.tipo === 'conselho'">
                          <label for="conselho">Conselho</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'comite'">
                          <label for="comite">Comitê</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'forum'">
                          <label for="forum">Fórum</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'diretoria'">
                          <label for="diretoria">Diretoria</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'camara'">
                          <label for="camara">Câmara</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'colegio'">
                          <label for="colegio">Colégio</label>
                        </div>
                        <div class="options" v-if="colegiado.tipo === 'junta'">
                          <label for="junta">Junta</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.status">
                    <div class="radio">
                      <label class="radio-label"><b>Status:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.status === 'em_estruturacao'">  
                          <label for="em-estruturacao"> Em Estruturação</label>
                        </div>
                        <div class="options" v-if="colegiado.status === 'ativo'">  
                          <label for="ativo"> Ativo</label>
                        </div>
                        <div class="options" v-if="colegiado.status === 'inativo'">
                          <label for="inativo"> Inativo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.status === 'inativo'">
                  <div class="item" v-if="colegiado.status_motivo">
                    <label><b>Motivo do status inativo:</b></label>
                    <p>{{ colegiado.status_motivo }}</p>
                  </div>
                  <div class="item" v-if="colegiado.status_link_motivo">
                    <label><b>Link do Motivo do status inativo:</b></label>
                    <a :href="colegiado.status_link_motivo" target="_blank" rel="noopener noreferrer">{{colegiado.status_link_motivo}}</a>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.cargo_presidente !== null || colegiado.lista_triplice !== null || colegiado.indicacao_governador !== null">
                  <div class="item" v-if="colegiado.cargo_presidente !== null">
                    <div class="radio">
                      <label class="radio-label"><b>Cargo de presidente nomeação em comissão:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.cargo_presidente === true">
                          <label for="sim-cargo-presidente">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.cargo_presidente === false">
                          <label for="nao-cargo-presidente">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.lista_triplice !== null">
                    <div class="radio">
                      <label class="radio-label"><b>Lista tríplice:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.lista_triplice === true">
                          <label for="sim-lista">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.lista_triplice === false">
                          <label for="nao-lista">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexbox">
                    <div class="item" v-if="colegiado.indicacao_governador !== null">
                    <div class="radio">
                      <label class="radio-label"><b>Indicação do governador:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.indicacao_governador === true">
                          <label for="sim-indicacao">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.indicacao_governador === false">
                          <label for="nao-indicacao">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.nomeacao_governador !== null">
                    <div class="radio">
                      <label class="radio-label"><b>Nomeação via decreto do governador:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.nomeacao_governador === true">
                          <label for="sim-nomeacao-decreto">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.nomeacao_governador === false">
                          <label for="nao-nomeacao-decreto">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.quantidade_membros_titulares_minimo || colegiado.quantidade_membros_titulares_maximo || colegiado.quantidade_membros_suplentes_minimo || colegiado.quantidade_membros_suplentes_maximo">
                  <div class="item" v-if="colegiado.quantidade_membros_titulares_minimo || colegiado.quantidade_membros_titulares_maximo">
                    <label class="custom-label"><b>Quantidade de membros titulares:</b></label>
                    <div class="flexbox-min">
                      <div class="input-wrapper" v-if="colegiado.quantidade_membros_titulares_minimo">
                        <p>Minímo:
                        {{ colegiado.quantidade_membros_titulares_minimo }}</p>
                      </div>
                      <div class="input-wrapper" v-if="colegiado.quantidade_membros_titulares_maximo">
                        <p>Mâximo:
                        {{ colegiado.quantidade_membros_titulares_maximo }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.quantidade_membros_suplentes_minimo || colegiado.quantidade_membros_suplentes_maximo">
                    <label class="custom-label"><b>Quantidade de membros suplentes:</b></label>
                    <div class="flexbox-min">
                      <div class="input-wrapper" v-if="colegiado.quantidade_membros_suplentes_minimo">
                        <p>Minímo:
                        {{ colegiado.quantidade_membros_suplentes_minimo }}</p>
                      </div>
                      <div class="input-wrapper" v-if="colegiado.quantidade_membros_suplentes_maximo">
                        <p>Mâximo:
                        {{ colegiado.quantidade_membros_suplentes_maximo }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.regimento_interno || colegiado.tipo_regimento || colegiado.link_regimento_interno || colegiado.anexos">
                  <div class="item" v-if="colegiado.regimento_interno">
                    <label><b>Regimento interno:</b></label>
                    {{ colegiado.regimento_interno }}
                  </div>
                  <div class="item" v-if="colegiado.tipo_regimento === 'link' && colegiado.link_regimento_interno">
                    <label><b>Link do regimento interno:</b></label>
                    <a :href="this.colegiado.link_regimento_interno" target="_blank" rel="noopener noreferrer">{{ this.colegiado.link_regimento_interno }}</a>
                  </div>
                  <div class="upload item" v-if="colegiado.tipo_regimento === 'upload' && colegiado.anexos && colegiado.anexos.length > 0">
                    <label><b>Anexo do regimento interno:</b></label>
                    <a :href="colegiado.anexos[0].url">
                      <p>{{ colegiado.anexos[0].titulo }}</p>
                    </a>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.duracao_mandato || colegiado.intervalo_mandato">
                  <div class="item" v-if="colegiado.duracao_mandato">
                    <label class="custom-label"><b>Duração do mandato:</b></label>
                    <p>{{ colegiado.duracao_mandato }} anos</p>
                  </div>
                  <div class="item" v-if="colegiado.intervalo_mandato">
                    <label class="custom-label"><b>Intervalo do mandato:</b></label>
                    <div class="input-wrapper">
                       <p>{{ colegiado.intervalo_mandato }} anos</p>
                    </div>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.remunerado !== null">
                  <div class="item">
                    <div class="radio">
                      <label class="radio-label"><b>Remunerado:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.remunerado === true">
                          <label for="sim-remunerado">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.remunerado === false">
                          <label for="nao-remunerado">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.remunerado === true && colegiado.valor_remunerado">
                    <label><b>Valor remunerado:</b></label>
                    <p>{{ colegiado.valor_remunerado }}</p>
                  </div>
                </div>
                <div class="flexbox" v-if="colegiado.referendar_nome !== null || colegiado.sabatina_alego !== null">
                  <div class="item" v-if="colegiado.referendar_nome !== null">
                    <div class="radio">
                      <label class="radio-label"><b>Referendar os nomes pelos chefes de cada poder:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.referendar_nome === true">
                          <label for="sim-referendar">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.referendar_nome === false">
                          <label for="nao-referendar">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.sabatina_alego !== null">   
                    <div class="radio">
                      <label class="radio-label"><b>Sabatina da ALEGO:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.sabatina_alego === true">
                          <label for="sim-sabatina">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.sabatina_alego === false">
                          <label for="nao-sabatina">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                <div class="flexbox" v-if="colegiado.permite_reconducao !== null">
                  <div class="item">
                    <div class="radio">
                      <label class="radio-label"><b>Permite recondução:</b></label>
                      <div class="radio-item">
                        <div class="options" v-if="colegiado.permite_reconducao === true">
                          <label for="sim-permite">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.permite_reconducao === false">
                          <label for="nao-permite">Não</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item" v-if="colegiado.permite_reconducao === true && colegiado.quantidade_reconducao !== null">
                    <label><b>Quantidade de recondução:</b></label>
                    {{ colegiado.quantidade_reconducao }}
                  </div>
                </div>
                
                <div class="flexbox" v-if="colegiado.legislacao || colegiado.link_legislacao || colegiado.representantes_sociedade">
                  <div class="item" v-if="colegiado.legislacao">
                    <label><b>Legislação:</b></label>
                    <p>{{ colegiado.legislacao }}</p>
                  </div>
                  <div class="item" v-if="colegiado.link_legislacao">
                    <label><b>Link da legislação:</b></label>
                    <a :href="colegiado.link_legislacao" target="_blank" rel="noopener noreferrer">{{colegiado.link_legislacao}}</a>
                  </div>
                </div>
                <div class="flexbox">
                    <div class="item" v-if="colegiado.representantes_sociedade">
                        <label><b>Representantes da sociedade civil organizada:</b></label>
                        <p>{{ colegiado.representantes_sociedade }}</p>
                    </div>
                </div>
                <div class="flexbox">
                  
                    <div class="item" v-if="colegiado.presidente_indicado  !== null">
                        <label><b>Presidente do conselho é indicado pelo Governador?</b></label>
                        <div class="options" v-if="colegiado.presidente_indicado === true">
                          <label for="sim-presi-indicado">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.presidente_indicado === false">
                          <label for="nao-presi-indicado">Não</label>
                        </div>
                    </div>
                    <div class="item" v-if="colegiado.membro_eleito  !== null">
                        <label><b>Membro eleito em assembleia?</b></label>
                        <div class="options" v-if="colegiado.membro_eleito === true">
                          <label for="sim-eleito">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.membro_eleito === false">
                          <label for="nao-eleito">Não</label>
                        </div>
                    </div>
                </div>
                <div class="flexbox">
                    <div class="item" v-if="colegiado.presidente_admin !== null">
                        <label><b>Presidente é membro do Conselho de Administração?</b></label>
                        <div class="options" v-if="colegiado.presidente_admin === true">
                          <label for="sim-presi-conselho">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.presidente_admin === false">
                          <label for="nao-presi-conselho">Não</label>
                        </div>
                    </div>
                    <div class="item" v-if="colegiado.continua_mandato !== null">
                        <label><b>Continuidade no mandato?</b></label>
                        <div class="options" v-if="colegiado.continua_mandato === true">
                          <label for="sim-continuidade">Sim</label>
                        </div>
                        <div class="options" v-if="colegiado.continua_mandato === false">
                          <label for="nao-continuidade">Não</label>
                        </div>
                    </div>
                </div>
                <div class="flexbox" v-if="colegiado.observacao">
                  <div class="item">
                    <label><b>Observação:</b></label>
                    <p>{{ colegiado.observacao }}</p>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="bt-editar">
              <div class="div-center">
                <div :id="colegiado.id">
                  <a :href="'/colegiados/colegiado-edit/' + colegiado.id">
                    <button id="botao-editar">
                      Editar
                    </button>
                  </a>
                </div>
              </div>
            </div>  
          </div>
        </tab>
        <tab class="tab-conteudo" title="Mandatos">       
          <div class="form centro">
            <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
            <div class="caixa-form-blocos item" v-if="mandatos">
              <exibirMandatos :mandatos="mandatos" ></exibirMandatos>
            </div>
          </div>
        </tab>
      </tabs>
      <div>
        <Footer
          :createdByLabel="'Criado por'"
          :criadoPor="colegiado.criado_por"
          :lastUpdatedByLabel="'Última atualização feita por'"
          :atualizadoPor="colegiado.atualizado_por"
          :emLabel="' em '"
          :SistemaLabel="'Sistema'"
          :created_at="colegiado.created_at"
          :updated_at="colegiado.updated_at"
        />
      </div>
    </div>
  </template>
  
  
<script>
    import BotaoVoltar from "../BotaoVoltar.vue";
    import ColegiadoApi from "../../services/ColegiadoApi";
    import { parseISO, format } from "date-fns";
    import Footer from "../Footer.vue";
    import Tab from '../Tab.vue';
    import Tabs from '../Tabs.vue';
    import exibirMandatos from './ExibirMandato.vue'

  
    export default {
      name: "ExibirColegiado",
      props: {
        colegiado: Object,
        mandatos: Array,
      },
      data() {
        return {
          count: null,
          salvando: false,
          erro: false,
          erroSalvar: false,
          hasError: false,
          r: false,
          dadosColegiados: {
            criado_por: null,
            atualizado_por: null,
            created_at: null,
            updated_at: null,
          },
        };
      },
      created() {
        this.ColegiadoApi = new ColegiadoApi();
      },
      components: {
        BotaoVoltar,
        Footer,
        Tab,
        Tabs,
        exibirMandatos
      },
      methods: {
        formatarStatus(valor) {
          if (valor === 'em_estruturacao') {
            return 'Em estruturação';
          } else if (valor === 'ativo') {
            return 'Ativo';
          } else if (valor === 'inativo') {
            return 'Inativo';
          }
  
          return valor; 
        },
        urlPdf: function(anexo) {
          return `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/anexo/${anexo}`;
        },
        formatarDataHora: function (value) {
          return format(parseISO(value), "dd/MM/yyyy");
        },
        resize(event) {
          const textarea = event.target;
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        },
      },
    };
</script>
  

<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Karla', sans-serif;
    }

    .item {
        margin: .3rem 0
    }

    @media screen and (min-width: "1066px") {
        .tab-conteudo {
            height: fit-content;
            display: inline;
            .centro {
                max-width: 100%;
            }
        }

        .wrapper {
            width: 100%;
            min-height: fit-content;
            background-color: #fff;
            margin: 0 auto 1rem auto;
            padding: 20px;

            .centro {
                max-width: 85rem;
            }
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }

        .topo-header {
          display: flex;
          align-items: center;

            img {
                height: 1.5rem;
            }
        }
        
        .v-select {
            font-size: .9rem;
            li {
                color: #000;
                padding: .5rem;
                background-color: white;
            }

            li:hover {
                background-color: #dbdbdb;
                font-weight: bold;
            }

            input.vs__search {
                border: none;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            box-shadow: #005516 0px 5px 15px;

            .upload {
                width: fit-content;
                #anexos {
                    display: grid;
                    gap: .5rem;
                    width: auto;

                    .itemTop {
                        display: flex;
                        gap: .7rem;
                        align-items: center;

                        label {
                            width: fit-content;
                            height: fit-content;
                        }

                        button {
                            margin: 0;
                        }
                    }

                    .caixa-form {
                        border-radius: 1rem;
                        padding: 0rem;
                    }
                }
            }

                .flexbox {
                    display: flex;
                    margin: 1rem 0 0 0;
                    gap: 2rem;

                    .item {
                        margin-left: 0;
                        display: grid;
                        width: -webkit-fill-available;

                        .radio {
                            display: grid;
                            cursor: default;

                            .radio-item {
                                display: flex;
                                gap: .7rem;
                                cursor: default;
                                align-self: center;

                                input {
                                    margin: 0;
                                    cursor: pointer;
                                }
                                label {
                                    margin: 0;
                                }
                                .options {
                                    display: flex;
                                    gap: .3rem;
                                }
                            }
                        }
                        .flexbox-min {
                            display: flex;
                            width: fit-content;
                            gap: 1rem;
                        }
                        label {
                            display: inline;
                            margin-bottom: 0.3rem;
                            color: black;
                            font-family: "Montserrat-Medium";
                            font-size: 0.9rem;
                            width: fit-content;
                        }
                    }
                }

                .flexbox-custom-1 {
                    display: grid;
                    grid-template-columns: 1fr .5fr .5fr;
                    gap: 1rem;
                    margin: 1rem 0;

                    .item {
                        margin-left: 0;
                        display: grid;
                        width: -webkit-fill-available;

                        .radio {
                            display: grid;
                            cursor: default;

                            .radio-item {
                                display: flex;
                                gap: .7rem;
                                cursor: default;
                                align-self: center;

                                input {
                                    margin: 0;
                                    cursor: pointer;
                                }
                                label {
                                    margin: 0;
                                }
                                .options {
                                    display: flex;
                                    gap: .3rem;
                                }
                            }
                        }
                        .flexbox-min {
                            display: flex;
                            width: fit-content;
                            gap: 1rem;
                        }
                        label {
                            display: inline;
                            margin-bottom: 0.3rem;
                            color: black;
                            font-family: "Montserrat-Medium";
                            font-size: 0.9rem;
                            width: fit-content;
                        }
                    }
                }

                .flexbox-custom-2 {
                    display: grid;
                    grid-template-columns: .1fr .9fr;
                    gap: 1rem;
                    margin: 1rem 0;

                .item {
                    margin-left: 0;
                    .radio {
                        display: grid;

                            .radio-item {
                                gap: .4rem;
                                cursor: default;

                                input {
                                    margin: 0;
                                }
                                label {
                                    margin: 0 .5rem 0 0;
                                }
                            }
                        }
                    }    
                }

                .flexbox-custom-3 {
                    display: grid;
                    grid-template-columns: .2fr .8fr;
                    gap: 1rem;
                    margin: 1rem 0;

                .item {
                    margin-left: 0;
                    .radio {
                        display: grid;

                            .radio-item {
                                gap: .4rem;
                                cursor: default;
                                input {
                                    margin: 0;
                                }
                                label {
                                    margin: 0 .5rem 0 0;
                                }
                            }
                        }
                    }    
                }

            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .5rem;
                margin: 1.5rem 0 0 0;

                p {
                    text-align: center;
                    margin: .2rem .2rem;
                }
            }

            .btn-carregar-dados:hover {
                background-color: #139b2cbc;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }

            input,
            the-mask {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            input[type=number] {
                width: auto;
                padding: 0.3rem;
            }

            input[type=text] {
                padding-left: .5rem;
            }

            input[type="file"] {
                display: none;
            }

            input:disabled {
                background-color: white;
                cursor: not-allowed;
            }

            textarea#body {
                padding: .5rem 0 0 .5rem;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }
        }

        .bt-editar {
          text-align: center;
          margin: 1.5rem 0 0 0;

          button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
          }

          label {
            display: none;
            padding-left: 10px;
            width: 10px;
          }

          #botao-editar:hover {
            background-color: #4CAF50;
          }

          #botao-editar-tab:hover {
            background-color: #4CAF50;
          }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }
  }

  @media screen and (min-width: "60px") and (max-width: "1065px") {
      .wrapper {
          width: 100%;
          min-height: 100vh;
          background-color: #f8f8f8;
          margin: 0;
          padding: 20px;
      }

      h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
      }

      .topo-header {
          display: flex;
          align-items: center;

          img {
              height: 1.5rem;
          }
      }

      .caixa-form {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem;
          border: 2px solid #00a339;

          .btn-carregar-dados {
              align-self: center;
              width: fit-content;
              height: fit-content;
              cursor: pointer;
              color: #fff;
              background-color: #139b2b;
              border: none;
              font-size: .8rem;
              border-radius: 2rem;
              padding: .2rem .8rem;
          }

          .btn-carregar-dados:hover {
              background-color: #139b2cbc;
          }

          input {
              border-radius: 0.5rem;
              border: solid 0.1rem;
          }

          /* input[type="file"]{
                      border-radius: 0.5rem;
                      border: none;
                      width: 44.27rem;
                  } */
          input[type="file"] {
              display: none;
          }

          .custom-file-upload {
              border-radius: 0.5rem;
              border: solid 0.1rem;
              display: inline-block;
              padding: 6px 12px;
              margin-left: 0.4rem;
              margin-right: 0.4rem;
              cursor: pointer;
          }

          input:disabled {
            background-color: white;
            cursor: not-allowed;
          }

          h2 {
              margin-top: 0;
              text-transform: uppercase;
              color: #005516;
              font-size: 1.2rem;
              font-family: "Montserrat-Bold";
              cursor: pointer;
          }

          .item {
              display: grid;
              margin: 1rem 0;

              label {
                  display: inline;
                  margin-bottom: 0.3rem;
                  color: black;
                  font-family: "Montserrat-Medium";
                  font-size: 0.9rem;
              }

              input {
                  height: 2rem;
                  font-family: "Montserrat-Medium";
                  padding-left: 0.5rem;
                  font-size: 0.9rem;
              }

              a:link {
                  color: #ffa600;
              }

              a:link:hover {
                  color: #e49503;
              }

              select {
                  height: 2rem;
                  width: 100%;
                  border-radius: 0.5rem;
                  border: solid 0.1rem;
                  background-color: white;
                  font-family: "Montserrat-Medium";
                  font-size: 0.95rem;
              }

              .upload {
                  background-color: #ffa600;
                  color: white;
                  border: none;
                  font-size: 0.9rem;
                  padding: 2px 6px;
                  border-radius: 0.2rem;
                  margin-left: 0.4rem;
                  margin-right: 0.4rem;
              }

              /* .upload{
                      color: #ffa600;
                      border-radius: 0.5rem;
                      border: #ffa600 solid 0.124rem;
                      display: inline-block;
                      padding: 2px 6px;
                      font-size: 0.9rem;
                      transition: 0.2s;
                      margin-left: 0.4rem;
                      margin-right: 0.4rem;
                      font-family: "Montserrat-Medium";
                      }
                      .upload:hover{
                      color: white;
                      background-color: #ffa600;
                      } */
              .textarea {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0 0 0 1px #000;
                  border: 5px solid transparent;
                  outline: none;
                  padding: 4px;
                  min-height: 150px;
                  box-sizing: border-box;
                  font-family: "Montserrat-Medium";
                  font-size: 0.93rem;
                  color: black;
              }

              .textarea:focus {
                  outline: 1px solid !important;
                  box-shadow: 0 0 0 2px black;
              }

              .mx-datepicker {
                  width: 100%;
              }

              .checkbox {
                  input {
                      width: auto;
                  }

                  .check {
                      --background: #fff;
                      --border: #d1d6ee;
                      --border-hover: #bbc1e1;
                      --border-active: #1b5138;
                      --tick: #fff;
                      position: relative;
                      cursor: pointer;

                      input,
                      svg {
                          width: 21px;
                          height: 21px;
                          display: inline-block;
                      }

                      input {
                          appearance: none;
                          -moz-appearance: none;
                          position: relative;
                          outline: none;
                          background: var(--background);
                          border: none;
                          margin: 0;
                          transform: translateY(5px);
                          padding: 0;
                          cursor: pointer;
                          border-radius: 4px;
                          transition: box-shadow 0.3s;
                          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                          &:hover {
                              --s: 2px;
                              --b: var(--border-hover);
                          }

                          &:checked {
                              --b: var(--border-active);
                          }
                      }

                      svg {
                          pointer-events: none;
                          fill: none;
                          stroke-width: 2px;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke: var(--stroke, var(--border-active));
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 21px;
                          height: 21px;
                          transform: scale(var(--scale, 1)) translateZ(1);
                          margin-top: 0rem;
                      }

                      span {
                          transform: translateY(-5px);
                          display: inline;
                          margin-left: 0.5rem;
                      }

                      &.path {
                          input {
                              &:checked {
                                  --s: 2px;
                                  transition-delay: 0.4s;

                                  &+svg {
                                      --a: 16.1 86.12;
                                      --o: 102.22;
                                  }
                              }
                          }

                          svg {
                              stroke-dasharray: var(--a, 86.12);
                              stroke-dashoffset: var(--o, 86.12);
                              transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                          }
                      }
                  }
              }
          }

          .multiselect {
              width: calc(100vw - 5.5rem);
          }
      }

      .caixa-form-blocos {
          background-color: #efefef;
          padding: 1rem;
          border-radius: 0.2rem;

          input {
              border-radius: 0.5rem;
              border: solid 0.1rem;
          }

          /* input[type="file"]{
                      border-radius: 0.5rem;
                      border: none;
                      width: 44.27rem;
                  } */
          input[type="file"] {
              display: none;
          }

          .custom-file-upload {
              border-radius: 0.5rem;
              border: solid 0.1rem;
              display: inline-block;
              padding: 6px 12px;
              margin-left: 0.4rem;
              margin-right: 0.4rem;
              cursor: pointer;
          }

          input:disabled {
              background: white;
          }

          h2 {
              margin-top: 0;
              text-transform: uppercase;
              color: #005516;
              font-size: 1.2rem;
              font-family: "Montserrat-Bold";
              cursor: pointer;
          }

          .item {
              display: block;
              margin: 1rem 0;

              label {
                  display: inline;
                  margin-bottom: 0.3rem;
                  color: black;
                  font-family: "Montserrat-Medium";
                  font-size: 0.9rem;
              }

              input {
                  height: 2rem;
                  font-family: "Montserrat-Medium";
                  padding-left: 0.5rem;
                  font-size: 0.9rem;
              }

              a:link {
                  color: #ffa600;
              }

              a:link:hover {
                  color: #e49503;
              }

              select {
                  height: 2rem;
                  width: 100%;
                  border-radius: 0.5rem;
                  border: solid 0.1rem;
                  background-color: white;
                  font-family: "Montserrat-Medium";
                  font-size: 0.95rem;
              }

              .upload {
                  background-color: #ffa600;
                  color: white;
                  border: none;
                  font-size: 0.9rem;
                  padding: 2px 6px;
                  border-radius: 0.2rem;
                  margin-left: 0.4rem;
                  margin-right: 0.4rem;
              }

              /* .upload{
                      color: #ffa600;
                      border-radius: 0.5rem;
                      border: #ffa600 solid 0.124rem;
                      display: inline-block;
                      padding: 2px 6px;
                      font-size: 0.9rem;
                      transition: 0.2s;
                      margin-left: 0.4rem;
                      margin-right: 0.4rem;
                      font-family: "Montserrat-Medium";
                      }
                      .upload:hover{
                      color: white;
                      background-color: #ffa600;
                      } */
              .textarea {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0 0 0 1px #000;
                  border: 5px solid transparent;
                  outline: none;
                  padding: 4px;
                  min-height: 150px;
                  box-sizing: border-box;
                  font-family: "Montserrat-Medium";
                  font-size: 0.93rem;
                  color: black;
              }

              .textarea:focus {
                  outline: 1px solid !important;
                  box-shadow: 0 0 0 2px black;
              }

              .mx-datepicker {
                  width: 100%;
              }

              .checkbox {
                  input {
                      width: auto;
                  }

                  .check {
                      --background: #fff;
                      --border: #d1d6ee;
                      --border-hover: #bbc1e1;
                      --border-active: #1b5138;
                      --tick: #fff;
                      position: relative;
                      cursor: pointer;

                      input,
                      svg {
                          width: 21px;
                          height: 21px;
                          display: inline-block;
                      }

                      input {
                          appearance: none;
                          -moz-appearance: none;
                          position: relative;
                          outline: none;
                          background: var(--background);
                          border: none;
                          margin: 0;
                          transform: translateY(5px);
                          padding: 0;
                          cursor: pointer;
                          border-radius: 4px;
                          transition: box-shadow 0.3s;
                          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                          &:hover {
                              --s: 2px;
                              --b: var(--border-hover);
                          }

                          &:checked {
                              --b: var(--border-active);
                          }
                      }

                      svg {
                          pointer-events: none;
                          fill: none;
                          stroke-width: 2px;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke: var(--stroke, var(--border-active));
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 21px;
                          height: 21px;
                          transform: scale(var(--scale, 1)) translateZ(1);
                          margin-top: 0rem;
                      }

                      span {
                          transform: translateY(-5px);
                          display: inline;
                          margin-left: 0.5rem;
                      }

                      &.path {
                          input {
                              &:checked {
                                  --s: 2px;
                                  transition-delay: 0.4s;

                                  &+svg {
                                      --a: 16.1 86.12;
                                      --o: 102.22;
                                  }
                              }
                          }

                          svg {
                              stroke-dasharray: var(--a, 86.12);
                              stroke-dashoffset: var(--o, 86.12);
                              transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                          }
                      }
                  }
              }
          }

          .multiselect {
              width: 100%;
          }
      }

      .blocos-textos {
          margin: 1rem 0rem;
      }

      .bt-salvar {
          text-align: center;
          margin: 1.5rem 0 0 0;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
              p {
                  margin: 0;
              }
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-salvar-tab {
          text-align: center;
          margin: 1rem;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-excluir {
          img {
              cursor: pointer;
              width: 23px;
              padding: 0.2rem;
              height: 1.4rem;
              display: inline-block;
          }

          button {
              border-radius: 1rem;
              border: none;
          }
      }

      .spinner-loading {
          visibility: hidden;
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          margin: 0rem .5rem 0rem -0.5rem;
      }

      #botao-salvar:hover {
          background-color: #4CAF50;
      }

      #botao-salvar-tab:hover {
          background-color: #4CAF50;
      }
  }
</style>
