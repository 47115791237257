import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class MandatosApi {
  cancelTokens = {
    getDadosRhNet: null,
    getMandatos: null,
    setMandatos: null,
    getMembros: null,
  };
  
  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getMandatos({ page = 1, qtd_por_pagina, 
    data_inicio,
    data_termino,
    categorias_representativas_id,
    ato_nomeacao,
    link_ato_nomeacao,
    cargo_membro,
    membros_id,
    tipos_mandatos_id,
    status_mandatos_id,
    colegiados_id,
    mandatos_id,
    funcao,
    tipo_membro }) {

    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      data_inicio,
      data_termino,
      categorias_representativas_id,
      ato_nomeacao,
      link_ato_nomeacao,
      cargo_membro,
      membros_id,
      tipos_mandatos_id,
      status_mandatos_id,
      colegiados_id,
      mandatos_id,
      funcao,
      tipo_membro
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMandatos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMandatos = null;
    return data;
  }

  async getMandato(mandatosId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMandatos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/${mandatosId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMandatos = null;

    return data;
  }

  async getMembros(cpf) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMembros = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/membros`,
      {
        params: {
          cpf: cpf,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMembros = null;

    return data;
  }

  async getCargosMembro(idMembro) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargosMembro = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/cargos_membro/${idMembro}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargosMembro = null;

    return data;
  }

  async getMembroId(cpf) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMembro = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/getMembroId`,
      {
        params: {
          cpf: cpf,
        },
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMembro = null;

    return data;
  }

  async setMandatos(mandatos) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setMandatos = source;

    if (mandatos.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/${mandatos.id}`,
        {
          cancelToken: source.token,
          ...mandatos,
        }
      );

      this.cancelTokens.setMandatos = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos`,
        {
          cancelToken: source.token,
          ...mandatos,
        }
      );

      this.cancelTokens.setMandatos = null;

      return data;
    }
  }
  
  async getColegiado(colegiadoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getColegiado = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/${colegiadoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getColegiado = null;

    return data;
  }

  async getTipoMandato() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipoMandato = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/tipo_mandato`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipoMandato = null;

    return data;
  }

  async getCategoria() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoria = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/categoria_representativa`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoria = null;

    return data;
  }

  async getStatus() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getStatus = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/status_mandato`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatus = null;

    return data;
  }

  async destroyMandato(mandatoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyMandato = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/mandatos/${mandatoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyMandato = null;
    return data;
  }
}
export default MandatosApi;
