<template lang="html">
  <div
    :class='{"tabs__light": mode === "light", "tabs__dark": mode === "dark"}'
  >
    <ul class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        :class='{"tab__selected": (index == selectedIndex)}'
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      mode: {
        type: String,
        default: 'light'
      }
    },
    data () {
      return {
        selectedIndex: 0, // the index of the selected tab,
        tabs: []         // all of the tabs
      }
    },
    created () {
      this.tabs = this.$children
    },
    mounted () {
      this.selectTab(0)
    },
    methods: {
      selectTab (i) {
        this.selectedIndex = i

        // loop over all the tabs
        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i)
        })
      }
    }
  }
</script>

<style lang="css">

  ul.tabs__header {
    display: flex;
    justify-content: center;
    margin-right: 50px;
  }

  ul.tabs__header > li {
    padding: 13px 30px;
    border-radius: 10px;
    margin: 5px;
    display: inline-block;
    margin-right: 0px;
    cursor: pointer;
  }

  ul.tabs__header > li.tab__selected {
    font-weight: bold;
  }

  .tab {
    display: center;
    color: black;
    padding: 0px;
    border-radius: 10px;
    min-height: 400px;
  }

  .tabs__light li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__light li.tab__selected {
    background-color: #fff;
    color: #005516;
  }

  .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }

  .tabs__dark li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;

  }
</style>
