import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class MembroApi {
  cancelTokens = {
    getMembros: null,
    getMembro: null,
    setMembro: null,
    destroyMembro: null,
    getDadosRhNet: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getMembros({ page = 1, qtd_por_pagina, nome, servidor_publico, cpf }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      servidor_publico,
      cpf,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMembros = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMembros = null;
    return data;
  }

  async getMembro(membroId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMembro = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros/${membroId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMembro = null;

    return data;
  }

  async destroyMembro(membroId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyMembro = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros/${membroId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyMembro = null;
    return data;
  }

  async setMembro(membro) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.setMembro = source;
  
    let url;
    let method;
    let requestData;
  
    if (membro.id) {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros/${membro.id}`;
      method = 'put';
      requestData = {
        nome: membro.nome,
        servidor_publico: membro.servidor_publico,
        cpf: membro.cpf,
      };
    } else {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros`;
      method = 'post';
      requestData = {
        nome: membro.nome,
        servidor_publico: membro.servidor_publico,
        cpf: membro.cpf,
      };
    }
    try {
      const { data } = await axiosInstance({
        method: method,
        url: url,
        data: requestData,
        cancelToken: source.token
      });
  
      this.cancelTokens.setMembro = null;
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  } 
  async getDadosRhNet(cpf) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getDadosRhNet = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros/buscar_dados_rh_net.json?cpf=${cpf}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getDadosRhNet = null;
    return data;
  }
  
  async atualizarServidorPublico(cpf, servidorPublico) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.atualizarServidorPublico = source;

    const { data } = await axiosInstance.put(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/membros/atualizar_servidor_publico`,
      {
        cpf: cpf,
        servidor_publico: servidorPublico
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.atualizarServidorPublico = null;
    return data;
  }
  
}


export default MembroApi;
