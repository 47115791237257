<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Membro" />
            <h1>{{ membroForm.id ? "Editar" : "Novo" }} Membro</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form">
                        <div class="flexbox-2">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("CPF:") }}</b></label>
                                <the-mask :mask="['###.###.###-##']" placeholder="Digite o CPF" v-model="membroForm.cpf"/>
                            </div>
                            <div class="item">
                                <button id="btn-carregar-dados" class="btn-carregar-dados" @click.prevent="buscarDados(membroForm.cpf)">
                                    <img id="spinner-loading-carregar" class="spinner-loading"
                                        src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                    <p id="conteudo-botao-carregar">Carregar Dados</p>
                                </button>
                            </div>
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="membroForm.nome" />
                            </div>
                            <!-- Apenas exibe a opção Servidor Público após o carregamento dos dados -->
                            <div class="item">
                                <div class="radio">
                                    <label class="radio-label"><b>{{ possuiAsterisco("Servidor Público?") }}</b></label>
                                    <div class="radio-item">
                                        <div class="options">
                                            <label for="sim-servidor">Sim</label>
                                            <input type="radio" id="sim-servidor" name="radioOptions" value="true" v-model="membroForm.servidor_publico" disabled>
                                        </div>
                                        <div class="options">
                                            <label for="nao-servidor">Não</label>
                                            <input type="radio" id="nao-servidor" name="radioOptions" value="false" v-model="membroForm.servidor_publico" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bt-salvar">
                        <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                            <img id="spinner-loading" class="spinner-loading" src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                            <p id="conteudo-botao">{{ membroForm.id ? "Atualizar" : "Criar" }}</p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="modalConfirmacaoAtualizacaoVisivel" class="modal-exclusao">
            <div class="modal-mask">
                <div class="modal-container">
                    <div class="modal-header">
                        <h2>Deseja realmente atualizar este membro?</h2>
                    </div>
                    <div class="modal-footer">
                        <button class="btn-confirmar-exclusao" @click="confirmarAtualizacaoMembro">Sim</button>
                        <button class="btn-cancelar-exclusao" @click="fecharModalConfirmacaoAtualizacao">Não</button>
                    </div> -.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MembroApi from '../../services/MembroApi';
    import BotaoVoltar from '../../components/BotaoVoltar.vue';
    import MsgErroFormularios from '../MsgErroFormularios';

    export default {
        name: 'MembroForm',
        props: {
            membro: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                carregando: false,
                erro: false,
                verificarCarregamento: false,
                modalConfirmacaoAtualizacaoVisivel: false,
                msgErros: {
                    cpf: '',
                    servidor_publico: '',
                    nome: '',
                    default: '',
                },
                membroApi: null,
                membroForm: this.membro
                    ? {
                        id: this.membro.id,
                        cpf: this.membro.cpf,
                        servidor_publico: this.membro.servidor_publico,
                        nome: this.membro.nome, 
                    }
                    : {
                        id: null,
                        cpf: null,
                        servidor_publico: null,
                        nome: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
        },
        created() {
            this.membroApi = new MembroApi();
        },
        methods: {
            abrirModalConfirmacaoAtualizacao() {
                this.modalConfirmacaoAtualizacaoVisivel = true;
            },

            fecharModalConfirmacaoAtualizacao() {
                this.modalConfirmacaoAtualizacaoVisivel = false;
            },

            confirmarAtualizacaoMembro() {
                this.modalConfirmacaoAtualizacaoVisivel = false;
                this.prosseguirEnvioForm();
            },
            enviarForm() {

                if (this.membroForm.id) {
                    this.abrirModalConfirmacaoAtualizacao();
                } else {
                    this.prosseguirEnvioForm(); 
                }
            },
            async prosseguirEnvioForm() {
                this.erro = false;

                if (this.membroForm.id && this.membroForm.cpf !== this.membro.cpf && !this.verificarCarregamento) {
                    this.erro = true;
                    this.msgErros.default = 'Por favor, clique em "Carregar Dados" antes de enviar o formulário.';
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false;
                }

                try {
                    const flag = this.validarForm(this.membroForm);
                    if (flag === 1) {
                        this.erro = true;
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        return false;
                    }

                    this.carregando = true;
                    const data = await this.membroApi.setMembro(this.membroForm);
                    this.$router.push({
                        name: 'MembroShow',
                        params: { id: data.id },
                    });
                } catch (err) {
                    console.error(err);
                    this.erro = true;
                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros = 'Houve um erro ao salvar o registro. Por favor, tente novamente.';
                    }
                } finally {
                    this.carregando = false;
                }
                if (this.membroForm.servidor_publico === null) {
                    // Define o valor do servidor como "Não"
                    this.membroForm.servidor_publico = "false";
                }
                
            },
            async buscarDados(cpf) {
                this.erro = false;
                this.verificarCarregamento = true;
                try {
                    this.carregando = true;
                    const dados = await this.membroApi.getDadosRhNet(cpf);
                    if (dados && Object.keys(dados).length !== 0) {
                        // Se os dados forem encontrados, atualize o nome do membro
                        this.membroForm.nome = dados.nome;
                        // Defina o status do servidor como "Sim"
                        this.membroForm.servidor_publico = 'true';
                    } 
                } catch (err) {
                    this.membroForm.servidor_publico = 'false';

                        console.log(err);
                        this.carregando = false;
                        this.erro = true;


                        const { response } = err;
                        if (response && response.data) {
                            this.msgErros = response.data.mensagem;
                        } else {
                            this.msgErros =
                                "Houve um erro ao salvar o registro. Por favor tente novamente.";
                        }
                    }
                    if (this.membroForm.servidor_publico === null) {
                        // Define o valor do servidor como "Não"
                        this.membroForm.servidor_publico = "false";
                    }     
            },
            validarForm(membroForm) {
                let flag = 0;
                // Resetar as mensagens de erro
                this.msgErros = {
                    cpf: '',
                    servidor_publico: '',
                    nome: '',
                    default: '',
                };

                // Verificar se o CPF está preenchido
                if (!membroForm.cpf) {
                    this.msgErros.cpf = 'O campo CPF não foi preenchido.';
                    flag = 1;
                }
                //CPF tem que ser válido
                if(!this.validarCPF(membroForm.cpf)){
                    this.msgErros.cpf = "CPF inválido.";
                    flag = 1;
                }
                if (this.membroForm.servidor_publico === null) {
                        // Define o valor do servidor como "Não"
                        this.membroForm.servidor_publico = "false";
                }    
                if (!membroForm.nome) {
                    this.msgErros.nome = 'O campo nome não foi preenchido.';
                    flag = 1;
                }
                return flag;
            },
            validarCPF(cpf) {
                cpf = cpf.replace(/\D/g, '');
                if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
                    return false;
                }

                let soma = 0;
                let resto;

                for (let i = 1; i <= 9; i++) {
                    soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
                }
                resto = (soma * 10) % 11;

                if (resto === 10 || resto === 11) resto = 0;
                if (resto !== parseInt(cpf.charAt(9))) {
                    return false;
                }

                soma = 0;
                for (let i = 1; i <= 10; i++) {
                    soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
                }
                resto = (soma * 10) % 11;

                if (resto === 10 || resto === 11) resto = 0;
                if (resto !== parseInt(cpf.charAt(10))) {
                    return false;
                }

                return true;
            },
            possuiAsterisco(texto) {
                return '* ' + texto;
            },
            fecharErro() {
                this.erro = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Karla', sans-serif;
    }
    .modal-exclusao {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: fit-content;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 0 .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }
        }
        .modal-footer {
          margin: .7rem auto 0rem auto;
          place-content: space-evenly;
          font-size: 1rem;

          .btn-confirmar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #005516;
            color: #f2f2f2;
          }

          .btn-cancelar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #bd0000;
            color: #f2f2f2;
          }
        }
      }
    @media screen and (min-width: "1066px") {
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }
        .item {
            flex: 1;
            margin-right: 1rem;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }
        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;
        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }
            img {
                height: 1.5rem;
            }
        }

    .caixa-form {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

            .flexbox-1 {
                display: grid;
                grid-template-columns: 0.7fr 0.7fr 2fr 1fr;
                gap: 2rem;
            }

            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .5rem;
                margin: 1.5rem 0 0 0;
            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .5rem;
                margin: 1.5rem 0 0 0;

                p {
                    text-align: center;
                    margin: .2rem .2rem;
                }
            }
                p {
                    text-align: center;
                    margin: .2rem .2rem;
                }
            }

            .btn-carregar-dados:hover {
                background-color: #139b2cbc;
            }

        input,
        the-mask {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

            input[type="file"] {
                display: none;
            }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }

        .item {
            display: grid;
            margin: .5rem 0;
            width: -webkit-fill-available;

                .radio {
                    display: grid;
                    align-items: center;
                    height: fit-content;
                    width: fit-content;
                }

                .radio-label {
                    margin-bottom: 0.3rem;
                }

                .radio-item {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    label {
                        margin: 0;
                        font-size: .8rem;
                    }

                    input {
                        width: fit-content;
                        margin-left: 0rem;
                    }

                    .options {
                        display: flex;
                        align-items: center;
                        gap: .4rem;
                    }
                }

            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }

            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }

            a:link {
                color: #ffa600;
            }

            a:link:hover {
                color: #e49503;
            }

            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }

                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }
               
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }
        }
    }

    .bt-salvar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
            p {
                margin: 0;
            }
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .spinner-loading {
        visibility: hidden;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0rem .5rem 0rem -0.5rem;
    }

    #botao-salvar:hover {
        background-color: #4CAF50;
    }
}

@media screen and (min-width: "60px") and (max-width: "1065px") {
    .wrapper {
        width: 100%;
        min-height: 100vh;
        background-color: #f8f8f8;
        margin: 0;
        padding: 20px;
    }

    h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.8rem;
    }

    .topo-header {
        display: flex;
        align-items: center;

        img {
            height: 1.5rem;
        }
    }

    .caixa-form {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem;
        border: 2px solid #00a339;

        .btn-carregar-dados {
            align-self: center;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            color: #fff;
            background-color: #139b2b;
            border: none;
            font-size: .8rem;
            border-radius: 2rem;
            padding: .2rem .8rem;
        }

        .btn-carregar-dados:hover {
            background-color: #139b2cbc;
        }

        input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

            input[type="file"] {
                display: none;
            }

        .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
        }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }

        .item {
            display: grid;
            margin: 1rem 0;

            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }

            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }

            a:link {
                color: #ffa600;
            }

            a:link:hover {
                color: #e49503;
            }

            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }

            .upload {
                background-color: #ffa600;
                color: white;
                border: none;
                font-size: 0.9rem;
                padding: 2px 6px;
                border-radius: 0.2rem;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
            }

                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }

            .mx-datepicker {
                width: 100%;
            }

            .checkbox {
                input {
                    width: auto;
                }

                .check {
                    --background: #fff;
                    --border: #d1d6ee;
                    --border-hover: #bbc1e1;
                    --border-active: #1b5138;
                    --tick: #fff;
                    position: relative;
                    cursor: pointer;

                    input,
                    svg {
                        width: 21px;
                        height: 21px;
                        display: inline-block;
                    }

                    input {
                        appearance: none;
                        -moz-appearance: none;
                        position: relative;
                        outline: none;
                        background: var(--background);
                        border: none;
                        margin: 0;
                        transform: translateY(5px);
                        padding: 0;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: box-shadow 0.3s;
                        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                        &:hover {
                            --s: 2px;
                            --b: var(--border-hover);
                        }

                        &:checked {
                            --b: var(--border-active);
                        }
                    }

                    svg {
                        pointer-events: none;
                        fill: none;
                        stroke-width: 2px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke: var(--stroke, var(--border-active));
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 21px;
                        height: 21px;
                        transform: scale(var(--scale, 1)) translateZ(1);
                        margin-top: 0rem;
                    }

                    span {
                        transform: translateY(-5px);
                        display: inline;
                        margin-left: 0.5rem;
                    }

                    &.path {
                        input {
                            &:checked {
                                --s: 2px;
                                transition-delay: 0.4s;

                                &+svg {
                                    --a: 16.1 86.12;
                                    --o: 102.22;
                                }
                            }
                        }

                        svg {
                            stroke-dasharray: var(--a, 86.12);
                            stroke-dashoffset: var(--o, 86.12);
                            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                        }
                    }
                }
            }
        }

        .multiselect {
            width: calc(100vw - 5.5rem);
        }
    }

    .caixa-form-blocos {
        background-color: #efefef;
        padding: 1rem;
        border-radius: 0.2rem;

        input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
        }

            input[type="file"] {
                display: none;
            }

        .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
        }

        input:disabled {
            background: white;
        }

        h2 {
            margin-top: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
        }

        .item {
            display: block;
            margin: 1rem 0;

            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }

            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }

            a:link {
                color: #ffa600;
            }

            a:link:hover {
                color: #e49503;
            }

            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }

            .upload {
                background-color: #ffa600;
                color: white;
                border: none;
                font-size: 0.9rem;
                padding: 2px 6px;
                border-radius: 0.2rem;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
            }

                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }

            .mx-datepicker {
                width: 100%;
            }

            .checkbox {
                input {
                    width: auto;
                }

                .check {
                    --background: #fff;
                    --border: #d1d6ee;
                    --border-hover: #bbc1e1;
                    --border-active: #1b5138;
                    --tick: #fff;
                    position: relative;
                    cursor: pointer;

                    input,
                    svg {
                        width: 21px;
                        height: 21px;
                        display: inline-block;
                    }

                    input {
                        appearance: none;
                        -moz-appearance: none;
                        position: relative;
                        outline: none;
                        background: var(--background);
                        border: none;
                        margin: 0;
                        transform: translateY(5px);
                        padding: 0;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: box-shadow 0.3s;
                        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                        &:hover {
                            --s: 2px;
                            --b: var(--border-hover);
                        }

                        &:checked {
                            --b: var(--border-active);
                        }
                    }

                    svg {
                        pointer-events: none;
                        fill: none;
                        stroke-width: 2px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke: var(--stroke, var(--border-active));
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 21px;
                        height: 21px;
                        transform: scale(var(--scale, 1)) translateZ(1);
                        margin-top: 0rem;
                    }

                    span {
                        transform: translateY(-5px);
                        display: inline;
                        margin-left: 0.5rem;
                    }

                    &.path {
                        input {
                            &:checked {
                                --s: 2px;
                                transition-delay: 0.4s;

                                &+svg {
                                    --a: 16.1 86.12;
                                    --o: 102.22;
                                }
                            }
                        }

                        svg {
                            stroke-dasharray: var(--a, 86.12);
                            stroke-dashoffset: var(--o, 86.12);
                            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                        }
                    }
                }
            }
        }

        .multiselect {
            width: 100%;
        }
    }

    .blocos-textos {
        margin: 1rem 0rem;
    }

    .bt-salvar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
            p {
                margin: 0;
            }
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .bt-salvar-tab {
        text-align: center;
        margin: 1rem;

        button {
            background-color: #005516;
            color: white;
            border: none;
            font-size: 1rem;
            padding: 0.4rem 2rem;
            border-radius: 2rem;
            margin: 0.2rem;
        }

        label {
            display: none;
            padding-left: 10px;
            width: 10px;
        }
    }

    .bt-excluir {
        img {
            cursor: pointer;
            width: 23px;
            padding: 0.2rem;
            height: 1.4rem;
            display: inline-block;
        }

        button {
            border-radius: 1rem;
            border: none;
        }
    }

    .spinner-loading {
        visibility: hidden;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0rem .5rem 0rem -0.5rem;
    }

    #botao-salvar:hover {
        background-color: #4CAF50;
    }

    #botao-salvar-tab:hover {
        background-color: #4CAF50;
    }
}
</style>

  