<template>
    <div>
      <MembroForm :membro="null" />
    </div>
</template>

<script>
import MembroForm from "../../components/membro/MembroForm.vue";

export default {
    name: "MembroNew",
    components: {
        MembroForm,
    },
};
</script>

<style lang="scss" scoped></style>
