import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class StatusMandatoApi {
  cancelTokens = {
    getStatusMandatos: null,
    getStatusMandato: null,
    setStatusMandato: null,
    destroyStatusMandato: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getStatusMandatos({ page = 1, qtd_por_pagina, nome, ativo, codigo }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      ativo,
      codigo,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getStatusMandatos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/status_mandatos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatusMandatos = null;
    return data;
  }

  async getStatusMandato(statusMandatoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getStatusMandato = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/status_mandatos/${statusMandatoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatusMandato = null;

    return data;
  }

  async destroyStatusMandato(statusMandatoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyStatusMandato = source;

    ("Chamando a API para excluir o status do mandato com o ID:", statusMandatoId);

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/status_mandatos/${statusMandatoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyStatusMandato = null;
    return data;
  }
  async setStatusMandato(statusMandato) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.setStatusMandato = source;
  
    let url;
    let method;
    let requestData;
  
    if (statusMandato.id) {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/status_mandatos/${statusMandato.id}`;
      method = 'put';
      requestData = {
        status_mandato: { // Encapsula os dados em um objeto status_mandato
          nome: statusMandato.nome,
          ativo: statusMandato.ativo,
          codigo: statusMandato.codigo
        }
      };
    } else {
      url = `${process.env.VUE_APP_BASE_API_URL}/api/v1/status_mandatos`;
      method = 'post';
      requestData = {
        status_mandato: { // Encapsula os dados em um objeto status_mandato
          nome: statusMandato.nome,
          ativo: statusMandato.ativo,
          codigo: statusMandato.codigo
        }
      };
    }
    try {
      const { data } = await axiosInstance({
        method: method,
        url: url,
        data: requestData,
        cancelToken: source.token
      });
  
      this.cancelTokens.setStatusMandato = null;
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
}  

export default StatusMandatoApi;
