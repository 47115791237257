<template >
  <div class="fora">
    <div class="form centro">    
        <div v-if="this.mandatos">
          <fieldset v-for="mandato in mandatos " :key="mandato.id">
              <div class="dados">
                <div class="flexbox-1">
                  <p>Código Mandato:
                    <span>{{ mandato.id }}</span>
                  </p>
                </div >
                <div class="flexbox-1">
                  <p>CPF do Membro:
                    <span>{{ formatarCPF(mandato.cpf_membro) }}</span>
                  </p>
                  <p>Nome do Membro:
                    <span>{{ mandato.nome_membro }}</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Cargo do Membro:
                    <span v-if="mandato.cargo_membro">{{ mandato.cargo_membro }}</span>
                    <span v-else>Nenhum cargo encontrado</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Função do Membro:
                    <span>{{ getFuncao(mandato.funcao) }}</span>
                  </p>
                  <p>Tipo do Membro:
                    <span>{{ mandato.tipo_membro }}</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Tipo de Mandato:
                    <span>{{ mandato.tipo_mandato }}</span>
                  </p>
                  <p>Código Mandato Anterior:
                    <span>{{ mandato.mandatos_id }}</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Data de Início:
                    <span>{{ formatarData(mandato.data_inicio) }}</span>
                  </p>
                  <p>Data de Término:
                    <span>{{ formatarData(mandato.data_termino) }}</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Categoria Representada:
                    <span>{{ mandato.categoria_representativa }}</span>
                  </p>
                  <p>Status do Mandato:
                    <span>{{ mandato.status_mandato }}</span>
                  </p>
                </div>
                <div class="flexbox-1">
                  <p>Ato de Nomeação:
                    <span>{{ mandato.ato_nomeacao }}</span>
                  </p>
                  <p>Link do Ato de Nomeação:
                      <a :href="mandato.link_ato_nomeacao" target="_blank" rel="noopener noreferrer">
                          {{ mandato.link_ato_nomeacao ? mandato.link_ato_nomeacao.substring(0, 20) + '...' : ' ' }}
                      </a>
                  </p>
                </div>
              </div>
          </fieldset>
        </div>
        
    </div>
    
  </div>
</template>

<script>
 import { parseISO, format } from "date-fns";

 export default {
   name: "ExibirMandatos",
   props: {
     mandatos: Array,
   },
   data() {
     return {
       count: null,
       salvando: false,
       erro: false,
       erroSalvar: false,
       hasError: false,
       r: false,
       dadosColegiados: {
         criado_por: null,
         atualizado_por: null,
       },
       funcaomembro: null,
     };
   },
   components: {
   },
   methods: {
    formatarCPF(cpf) {
      cpf = cpf.replace(/\D/g, '');

      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

      return cpf;
    },
    formatarData: function (value) {
      return format(parseISO(value), "dd/MM/yyyy");
    },
    getFuncao(value) {
      if (value === 'vicepresidente'){
        return 'Vice-Presidente';
      }
      else if (value == 'presidente'){
        return 'Presidente';
      }
    }
   },
 };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 1066px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;
          margin: 0rem 0 1.5rem 0;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;
            
            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 1rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .gridbox {
              display: grid;
              gap: .2rem;

              span {
                margin: 0;
              }
            }
            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }
            .flexbox-2 {
              display: grid;
              grid-template-columns: 1.5fr 1fr 1fr;
              gap: 2rem;
            }
            .flexbox-3 {
              display: grid;
              grid-template-columns: .3fr .7fr;
              gap: 2rem;
            }
            .flexbox-4 {
              display: flex;
              gap: 2rem;
            }
            a {
              width: fit-content;
              height: fit-content;
              .icone-sei {
                width: 2rem;
              }
              .icone-sei:hover {
                width: 1.9rem;
              }
              .icone-sei-disabled {
                width: 2rem;
                cursor: not-allowed;
              }
            }
            .andamento-sei {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: 2rem;
        color: #000000;
        margin: 0 0 0 3rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
    .inline {
      display: inline-block;
    }
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
  }
  @media screen and (min-width: 80px) and (max-width: 1065px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
      .form {
        
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;
          margin: 0rem 0 1.5rem 0;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;
            
            p {
              font-family: "Montserrat-Medium";
              font-size: .9rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 1rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: .9rem;
              font-weight: normal;
            }

            .gridbox {
              display: grid;
              gap: .2rem;
              margin: 0;

              span {
                margin: 0;
              }
            }
            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }
            .flexbox-2 {
              display: grid;
              grid-template-columns: 1.5fr 1fr 1fr;
              gap: 2rem;
            }
            .flexbox-3 {
              display: grid;
              grid-template-columns: .3fr .7fr;
              gap: 2rem;
            }
            .flexbox-4 {
              display: grid;
              gap: 1rem;
            }
            .icone-sei {
              width: 2rem;
            }
            .icone-sei:hover {
              width: 1.9rem;
            }
            .andamento-sei {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: .9rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: fit-content;
        color: #000000;
        padding: 0;
        margin: 3rem 1rem 0 1rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
    .inline {
      display: inline-block;
    }
  }
</style>
