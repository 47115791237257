<template>
  <div class="menu-fixed">
    <div id="error" class="error">
      <button @click.prevent="fecharTelaMensagens()">
        <img src="../assets/imagens/icon-close.png" alt="Botão de fechar" class="botao-fechar">
      </button>
      <h2>Não foi possível salvar, pois:</h2>
      <ul>
        <template v-if="isObject">
          <li v-for="(mensagem, index) in filteredMessages" :key="index">
            {{ mensagem }}
          </li>
        </template>
        <template v-else>
          <li>{{ mensagens }}</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MsgErroFormularios",
  props: {
    mensagens: {
      type: [Object, String],
      required: true
    }
  },
  computed: {
    isObject() {
      return typeof this.mensagens === 'object' && !Array.isArray(this.mensagens);
    },
    filteredMessages() {
      if (this.isObject) {
        return Object.values(this.mensagens).filter(value => value !== null && value !== '');
      }
      return this.mensagens;
    }
  },
  methods: {
    fecharTelaMensagens() {
      this.$emit("fechar-erro");
    }
  }
};
</script>




<style lang="scss" scoped>
  .error {
    background-color: #f71216e7;
    border-radius: 7px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.9rem;
    text-align: center;

    button {
      float: right;
      margin: .3rem .3rem 0 0;
      border: none;
      background-color: transparent;
      .botao-fechar {
        width: 1rem;
      }
    }

    button:hover {
      margin: .4rem .4rem 0 0;
      .botao-fechar {
        width: .8rem;
      }
    }

    h2 {
      display: block;
      font-size: 1.4rem;
      padding-left: 1rem;
      margin-block-start: 0.83rem;
      margin-block-end: 00.83rem;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      font-family: "Montserrat-Medium";
    }

    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1rem;
      margin-block-end: 1rem;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        font-size: 0.98rem;
        display: list-item;
        text-align: left;
        color: black;
        background-color: #f7121609;
        font-family: "Montserrat-Medium";
        margin: .3rem 0;
      }
    }
  }

  /* .error{
  background-color: #F39999;
  border-radius: 7px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.9rem;
  h2{
    display: block;
    font-size: 1.5rem;
    margin-block-start: 0.83rem;
    margin-block-end: 00.83rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-family: "Montserrat-Medium";
  }
  ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li{
      display: list-item;
      text-align: left;
      color: black;
      background-color: #F39999;
      font-family: "Montserrat-Medium";
    }
  }
} */
</style>