<template>
  <div class = "index">
    <div class="topo-index">
      <h1>Sem permissão</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "SemPermissao"
};
</script>

<style lang="scss" scoped>
.index {
      .topo-index {
        padding-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          font-size: 2.8rem;
        }

        a {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          border: #005516 solid 0.15rem;
          padding: 0.3rem 2rem;
          border-radius: 0.2rem;
          transition: 0.2s;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        a:hover {
          color: white;
          background-color: #005516;
        }
      }

      .caixa-pesquisa {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem 1rem 0 0;
        .flex {
          display: inline-flex;
          gap: 1rem;
          margin: 1rem 0 0 0;
        }
        .row4 {
          display: grid;
          grid-template-columns: .25fr .25fr .25fr .25fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
          
        }

        .row2 {
          display: grid;
          grid-template-columns: .5fr .5fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }

        .row2-data {
          display: grid;
          grid-template-columns: repeat(4, 1fr); 
          gap: 1.5rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }

        input {
          border-radius: 0.5rem;
          border: solid 0.1rem;
        }

        h2 {
          margin: 0;
          text-transform: uppercase;
          color: #005516;
          font-size: 1.2rem;
          font-family: "Montserrat-Bold";
          cursor: pointer;
        }

        .item {
          display: grid;
          margin: .5rem 0 .5rem 0;

          label {
            width: fit-content;
            margin-bottom: 0.3rem;
            color: black;
            font-family: "Montserrat-Medium";
            font-size: 0.9rem;
            font-weight: 500;
          }

          input {
            height: 2rem;
            font-family: "Montserrat-Medium";
            padding-left: 0.5rem;
            font-size: 0.9rem;
          }

          select {
            height: 2rem;
            border-radius: 0.5rem;
            border: solid 0.1rem;
            background-color: white;
            font-family: "Montserrat-Medium";
            font-size: 0.84rem;
          }
        }

        .botoes-pesquisa {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;

          .bt-pesquisa {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #005516;
              color: white;
              border: #005516 solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }

          .bt-limpar {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #FFFFFF;
              color: #000;
              border: white solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1.5rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .n-div {
        display: flex;
        justify-content: space-between;

        .nresultados {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.9rem;
            font-family: "Montserrat-Bold";
            margin-top: 0.5rem;
          }
        }

        .npagina {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.9rem;
            font-family: "Montserrat-Medium";
            margin: 0.5rem .5rem 0 0;
          }

          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
            cursor: pointer;
          }
        }
      }

      .container-tabela {
        margin-top: .5rem;
        border-radius: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .resultados {
          width: 100%;
          height: max-content;
          margin: 0;
        }
      }
    }
</style>