<template>
  <header>
    <div class="wrapper">
      <nav>
        <div class="centro topo-menu">
          <div class="versao">
            <span>v{{ version }}</span>
          </div>
          <div class="usuario">
            <p>{{ usuario ? usuario.nome : 'TESTE' }}</p>
            <a @click="sair">
              <img src="../assets/imagens/icon-exit.svg" alt="Sair do Sistema" title="sair" class="sair" />
            </a>
          </div>
        </div>
      </nav>
      <div class="faixa-menu">
        <div class="icon-menu">
          <div class="logo">
            <img class="logo_sistema" src="../assets/imagens/logo-colegiados.png" alt="" />
          </div>
          <div class="logo">
            <img class="logo_goias" src="../assets/imagens/imagem-menu.png" alt="" />
          </div>
        </div>
        <div class="centro">
          <input id="atv-menu" type="checkbox" />
          <label for="atv-menu">
            <div class="menu">
              <span class="hamburger"></span>
            </div>
          </label>
          <ul id="ulMenu">
            <li v-for="(rotas, meta) in gruposRotas" :key="meta">
              <template v-for="rota in rotas">
                  <router-link v-if="rota.meta.raiz" :key="rota.name" :to="{ name: rota.name }" @click.native="menuButtonClick">
                    {{ rota.meta.label }}
                  </router-link>
              </template>
            </li>
          </ul>
          
        </div>          
      </div>
    </div>
  </header>
</template>

<script>

  import Auth from "../services/Auth";
  import rotasPrivadas from "../router/rotasPrivadas";
  import camelize from "../helpers/camelize";

  const AUTENTICAR_ROUTE = 'Autenticar';
  const get = require("lodash.get");

  export default {
    name: "Menu",
    created() {
      this.auth = new Auth();
    },
    data() {
      const auth = new Auth();
      const gruposRotas = rotasPrivadas.reduce((acc, value) => {
        const meta = get(value, "meta");
        if (meta) {
          if (!value.meta.requerPermissao || auth.usuario.funcionalidades.some(func => value.meta.permissoes.includes(func))) {
            if (acc[meta]) {
              acc[meta].push(value);
            } else {
              acc[meta] = [value];
            }
          }
        }
        return acc;
      }, {});
      return {
        gruposRotas,
        version: process.env.VUE_APP_VERSION
      };

    }, 
    methods: {
      menuButtonClick() {
        var inputMenu = document.getElementById("atv-menu")
        setTimeout(() => {
          inputMenu.click()
        }, 500);
      },
      sair() {
        try {
          this.$swal
            .fire({
              title: "Você tem certeza?",
              text: "Informações não salvas serão perdidas",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, sair",
              cancelButtonText: "Não"
            })
            .then(result => {
              if (result.isConfirmed) {
                this.auth.logout();
                this.$router.push({ name: AUTENTICAR_ROUTE });
              }
            });
        } catch (err) {
          console.log(err);
        }
      },
      camelize
    },
    computed: {
      usuario: function () {
        if (this.auth.usuario && this.auth.loggedIn()) {
          return this.auth.usuario;
        }
        return null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .topo-menu {
    .versao{
      padding-left: 1rem;
    }
  }

  .wrapper {
    width: 100%;
    background-color: #f8f8f8;
    margin: 0;
  }

  @media screen and (min-width: 1150px) {
    header {
      display: grid;
      
      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0rem 0.5rem;

        p {
          margin: .1rem 0 0 0;
          font-size: 0.9rem;
          color: white;
        }

        .sair {
          height: 1rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: -webkit-fill-available;
          padding: .3rem .5rem 1rem 0;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }

      .faixa-menu {
        background-color: #005516;
        display: grid;

        .centro {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0 1rem;

          ul {
            display: block;
            margin: 0 auto;
            list-style: none;
            padding: 0;
            text-align: center;
            min-width: 60rem;
            max-width: 70rem;

            li {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: flex-end;

              a {
                padding: .5rem 1rem .2rem 1rem;
                color: #efefef;
                text-decoration: none;
                font-size: 1rem;
                font-family: "Montserrat-Medium";
                color: white;
              }

              a:hover {
                text-decoration: none;
                color: #ffa600;
              }
            }

            .divider {
              display: none;
              border: 1px solid rgba(187, 187, 187, 0.946);
              margin-top: 1.7rem;
              height: 2rem;
            }
          }
        }

        .icon-menu {
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: auto;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 12rem;
              height: -webkit-fill-available;
              max-width: 14rem;
              padding: .5rem;
            }

            .logo_goias {
              max-height: 8rem;
              height: -webkit-fill-available;
              max-width: 33rem;
              padding: .5rem 1rem;
            }
          }
        }

        .menu {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: 0.5rem;
          top: 3.5rem;
          z-index: 3;
          cursor: pointer;

          .hamburger {
            display: block;
            background-color: #ffa600;
            width: 1.4rem;
            height: 2px;
            position: relative;
            z-index: 12;
            margin: 1rem auto;
          }

          .hamburger::before,
          .hamburger::after {
            background-color: #ffa600;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transition: 0.5s ease-in-out;
          }

          .hamburger::before {
            top: 0.4em;
          }

          .hamburger::after {
            bottom: 0.35em;
          }
        }

        input {
          display: none;
        }

        input:checked~nav {
          top: 3rem;
        }

        input:checked~label {
          .hamburger {
            transform: rotate(45deg);
          }

          .hamburger::before,
          .hamburger::after {
            transform: rotate(90deg);
            top: 0;
          }
        }

        input:checked~ul {
          left: 0;
        }

        a {
          img {
            height: 3rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1149px) {
    header {
      display: grid;

      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0rem 0.5rem;

        p {
          margin: .1rem 0 0 0;
          font-size: 1rem;
          color: white;
        }

        .sair {
          height: 1rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: -webkit-fill-available;
          padding: .3rem .5rem 1rem 0;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }

      .faixa-menu {
        background-color: #005516;
        display: grid;

        .centro {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0 1rem;

          ul {
            display: block;
            margin: 0 auto;
            list-style: none;
            padding: 0;
            text-align: center;
            min-width: 60rem;
            max-width: 70rem;

            li {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: flex-end;

              a {
                padding: .5rem 1rem .2rem 1rem;
                color: #efefef;
                text-decoration: none;
                font-size: 1rem;
                font-family: "Montserrat-Medium";
                color: white;
              }

              a:hover {
                text-decoration: none;
                color: #ffa600;
              }
            }

            .divider {
              display: none;
              border: 1px solid rgba(187, 187, 187, 0.946);
              margin-top: 1.7rem;
              height: 2rem;
            }
          }
        }

        .centro {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0 1.5rem;

          ul {
            display: block;
            margin: 0 auto;
            list-style: none;
            padding: 0;
            text-align: center;
            min-width: fit-content;

            li {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: flex-end;

              a {
                padding: .5rem .5rem .2rem .5rem;
                color: #efefef;
                text-decoration: none;
                font-size: 1rem;
                font-family: "Montserrat-Medium";
                color: white;
              }

              a:hover {
                text-decoration: none;
                color: #ffa600;
              }
            }

            .divider {
              display: none;
              border: 1px solid rgba(187, 187, 187, 0.946);
              margin-top: 1.7rem;
              height: 2rem;
            }
          }
        }

        .icon-menu {
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: auto;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 12rem;
              height: -webkit-fill-available;
              max-width: 14rem;
              padding: .5rem;
            }

            .logo_goias {
              max-height: 8rem;
              height: -webkit-fill-available;
              max-width: 33rem;
              padding: .5rem 1rem;
            }
          }
        }

        .menu {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: 0.5rem;
          top: 3.5rem;
          z-index: 3;
          cursor: pointer;

          .hamburger {
            display: block;
            background-color: #ffa600;
            width: 1.4rem;
            height: 2px;
            position: relative;
            z-index: 12;
            margin: 1rem auto;
          }

          .hamburger::before,
          .hamburger::after {
            background-color: #ffa600;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transition: 0.5s ease-in-out;
          }

          .hamburger::before {
            top: 0.4em;
          }

          .hamburger::after {
            bottom: 0.35em;
          }
        }

        input {
          display: none;
        }

        input:checked~nav {
          top: 3rem;
        }

        input:checked~label {
          .hamburger {
            transform: rotate(45deg);
          }

          .hamburger::before,
          .hamburger::after {
            transform: rotate(90deg);
            top: 0;
          }
        }

        input:checked~ul {
          left: 0;
        }

        a {
          img {
            height: 3rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: '50em') {
    .logo {
      width: 15rem;
      margin-bottom: .5rem;

      .router-link-active {
        border-bottom: solid 0.17rem #efefef;
      }

      .router-link:hover {
        border-bottom: none;
      }
    }

    .router-link-active {
      border-bottom: solid 0.17rem #ffa600;
    }

    .router-link-active:hover {
      border-bottom: solid 0.17rem #ffa600;
    }

    header {
      .faixa-menu {
        .menu {
          display: none;
        }

        ul {
          position: static;
          display: flex;
          background-color: transparent;
          justify-content: flex-start;
          align-self: flex-end;

          li {
            margin-bottom: 0;
          }

          a {
            color: #efefef;
            padding: 0.5rem 1rem .2rem 1rem;
          }

          a:hover {
            border-bottom: #ffa600 solid 0.15rem;
          }
        }

        img {
          height: 4rem;
        }
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 949px) {
    header {
      display: grid;

      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0rem 1rem;

        p {
          margin: .1rem 0 0 0;
          font-size: 1rem;
          color: white;
        }

        .sair {
          height: 1rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: -webkit-fill-available;
          padding: .3rem .5rem 1rem 0;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }

      .faixa-menu {
        background-color: #005516;
        display: grid;

        .centro {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0 .5rem;

          ul {
            display: block;
            margin: 0 auto;
            list-style: none;
            padding: 0;
            text-align: center;
            width: fit-content;

            li {
              display: flex;
              flex-wrap: wrap;
              gap: .8rem;
              align-items: flex-end;

              a {
                padding: .5rem .5rem .2rem .5rem;
                color: #efefef;
                text-decoration: none;
                font-size: .8rem;
                font-family: "Montserrat-Medium";
                color: white;
              }

              a:hover {
                text-decoration: none;
                color: #ffa600;
              }
            }

            .divider {
              display: none;
              border: 1px solid rgba(187, 187, 187, 0.946);
              margin-top: 1.7rem;
              height: 2rem;
            }
          }
        }

        .icon-menu {
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: auto;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 9rem;
              height: -webkit-fill-available;
              max-width: 12rem;
              padding: 0rem;
            }

            .logo_goias {
              max-height: 7rem;
              height: -webkit-fill-available;
              max-width: 30rem;
              padding: 0rem;
            }
          }
        }

        .menu {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: 0.5rem;
          top: 3.5rem;
          z-index: 3;
          cursor: pointer;

          .hamburger {
            display: block;
            background-color: #ffa600;
            width: 1.4rem;
            height: 2px;
            position: relative;
            z-index: 12;
            margin: 1rem auto;
          }

          .hamburger::before,
          .hamburger::after {
            background-color: #ffa600;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transition: 0.5s ease-in-out;
          }

          .hamburger::before {
            top: 0.4em;
          }

          .hamburger::after {
            bottom: 0.35em;
          }
        }

        input {
          display: none;
        }

        input:checked~nav {
          top: 3rem;
        }

        input:checked~label {
          .hamburger {
            transform: rotate(45deg);
          }

          .hamburger::before,
          .hamburger::after {
            transform: rotate(90deg);
            top: 0;
          }
        }

        input:checked~ul {
          left: 0;
        }

        a {
          img {
            height: 3rem;
          }
        }
      }

      .icon-menu {
          background-color: #efefef;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: auto;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 12rem;
              height: -webkit-fill-available;
              max-width: 14rem;
              padding: .5rem;
            }

            .logo_goias {
              max-height: 8rem;
              height: -webkit-fill-available;
              max-width: 33rem;
              padding: .5rem 1rem;
            }
          }
        }
    }
  }

  @media screen and (min-width: 560px) and (max-width: 799px) {
    header {
      display: grid;

      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .5rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .8rem;
          color: white;
        }

        .sair {
          height: .8rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: .1rem .5rem 0 0;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }

      .faixa-menu {
        display: grid;
        background-color: #005516;

        .centro {
          display: block;
          justify-content: center;
          align-items: center;
          max-width: 80rem;
          height: 3rem;

          ul {
            background-color: #005516;
            list-style: none;
            position: absolute;
            padding: 0;
            text-align: center;
            width: 100%;
            height: 100%;
            top: 22rem;
            left: -100%;
            transition: 0.2s linear;
            z-index: 1000;

            li {
              margin: 2rem auto;
              display: grid;

              a {
                background-color: #005516;
                color: #efefef;
                text-decoration: none;
                font-size: 1.1rem;
                font-family: "Montserrat-Medium";
                color: white;
                margin: 1rem 0;
              }

              a:hover {
                text-decoration: none;
                color: #ffa600;
              }
            }

            .divider {
              display: none;
              border: 1px solid rgba(187, 187, 187, 0.946);
              margin-top: 1.7rem;
              height: 2rem;
            }
          }
        }

        .icon-menu {
          background-color: #efefef;
          display: grid;
          align-items: center;
          justify-content: center;
          width: -webkit-fill-available;
          justify-items: center;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 9rem;
              height: -webkit-fill-available;
              max-width: 12rem;
              padding: 0rem;
            }

            .logo_goias {
              max-height: 7rem;
              height: -webkit-fill-available;
              max-width: 30rem;
              padding: 0rem;
            }
          }
        }

        .menu {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: 0.5rem;
          top: 20rem;
          z-index: 3;
          cursor: pointer;

          .hamburger {
            display: block;
            background-color: #ffa600;
            width: 1.4rem;
            height: 2px;
            position: relative;
            z-index: 12;
            margin: 1rem auto;
          }

          .hamburger::before,
          .hamburger::after {
            background-color: #ffa600;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            transition: 0.5s ease-in-out;
          }

          .hamburger::before {
            top: 0.4em;
          }

          .hamburger::after {
            bottom: 0.35em;
          }
        }

        input {
          display: none;
        }

        input:checked~nav {
          top: 3rem;
        }

        input:checked~label {
          .hamburger {
            transform: rotate(45deg);
          }

          .hamburger::before,
          .hamburger::after {
            transform: rotate(90deg);
            top: 0;
          }
        }

        input:checked~ul {
          left: 0;
        }

        a {
          img {
            height: 3rem;
          }
        }
      }
    }
  }

  @media screen and (min-width: 60px) and (max-width: 559px) {
    header {

      .usuario {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: .5rem .5rem;

        p {
          margin: .1rem 0 0 0;
          font-size: .8rem;
          color: white;
        }

        .sair {
          height: .8rem;
        }

        a {
          margin-left: .5rem;
        }
      }

      nav {
        background-color: #005516;

        .centro {
          display: flex;
          justify-content: space-between;
          max-width: 80rem;
          padding: .1rem .5rem 0 0;
        }

        span {
          color: white;
          font-size: 0.8rem;
        }

        a {
          img {
            height: 1rem;
          }
        }
      }
    }

    .faixa-menu {
      background-color: #005516;

      .centro {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 80rem;
        height: 4rem;
      }
      
      .icon-menu {
          background-color: #efefef;
          display: grid;
          align-items: center;
          justify-content: center;
          width: -webkit-fill-available;
          justify-items: center;

          .logo {
            width: fit-content;
            margin: 0px;
            height: -webkit-fill-available;
            margin: .3rem 2rem;

            .logo_sistema {
              max-height: 10rem;
              height: -webkit-fill-available;
              max-width: 11rem;
              padding: 0rem;
            }

            .logo_goias {
              max-height: 9rem;
              height: -webkit-fill-available;
              max-width: 29rem;
              padding: 0rem;
            }
          }
        }

      .menu {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 0.5rem;
        top: 20.5rem;
        z-index: 3;
        cursor: pointer;

        .hamburger {
          display: block;
          background-color: #ffa600;
          width: 1.4rem;
          height: 2px;
          position: relative;
          z-index: 12;
          margin: 1rem auto;
        }

        .hamburger::before,
        .hamburger::after {
          background-color: #ffa600;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.5s ease-in-out;
        }

        .hamburger::before {
          top: 0.4em;
        }

        .hamburger::after {
          bottom: 0.35em;
        }
      }

      input {
        display: none;
      }

      input:checked~nav {
        top: 3rem;
      }

      input:checked~label {
        .hamburger {
          transform: rotate(45deg);
        }

        .hamburger::before,
        .hamburger::after {
          transform: rotate(90deg);
          top: 0;
        }
      }

      input:checked~ul {
        left: 0;
      }

      a {
        img {
          height: 3rem;
        }
      }

      ul {
        background-color: #005516;
        list-style: none;
        position: absolute;
        padding: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 23rem;
        left: -100%;
        transition: 0.2s linear;
        z-index: 1000;

        li {
          margin: 2rem auto;
          display: grid;

          a {
            color: #efefef;
            text-decoration: none;
            font-size: 1.1rem;
            font-family: "Montserrat-Medium";
            color: white;
            margin: 1rem 0;
          }

          a:hover {
            text-decoration: none;
            color: #ffa600;
          }
        }

        .divider {
          display: none;
          border: 1px solid rgba(187, 187, 187, 0.946);
          margin-top: 1.7rem;
          height: 2rem;
        }
      }
    }
  }
</style>