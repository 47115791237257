<template>
    <div class="div-conteudo">
      <div class="index centro">
        <div class="topo-index">
            <h1>Home</h1>
        </div>
        <div class="cessoes">
          <h2>Mandatos com encerramento iminente</h2>
          <div>
            <div class="container-tabela">
              <TabelaResultadosHome :colegiados="colegiados30Dias" :dias="30" v-if="!carregando && !erro">
              </TabelaResultadosHome>
            </div>
            <div class="container-tabela">
              <TabelaResultadosHome :colegiados="colegiados60Dias" :dias="60" v-if="!carregando && !erro">
              </TabelaResultadosHome>
            </div>
            <div class="container-tabela">
              <TabelaResultadosHome :colegiados="colegiados90Dias" :dias="90" v-if="!carregando && !erro">
              </TabelaResultadosHome>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import TabelaResultadosHome from "../../components/home/TabelaResultadosHome.vue";
  import HomeApi from "../../services/HomeApi";
  import metaDados from "../../helpers/metaDados";
  
  export default {
    name: "Home",
    metaInfo() {
        return {
            title: "Home",
            titleTemplate: "%s - Casa Civil do Estado de Goiás",
            meta: [
                {
                    vmid: "og:title",
                    property: "og:title",
                    content: "Home - Casa Civil do Estado de Goiás",
                },
                ...metaDados(this.$route),
            ],
        };
    },
    data() {
      return {

        colegiados30Dias: [],
        colegiados60Dias: [],
        colegiados90Dias: [],
        carregando: true,
        erro: false,
      };
    },
    components: {
      TabelaResultadosHome,
    },
    created() {
      this.carregarColegiados();
    },
    methods: {
      async carregarColegiados() {
        try {
          this.carregando = true;
          this.erro = false;
  
          const homeApi = new HomeApi();
          const dataColegiados = await homeApi.getColegiados({
            qtd_por_pagina: 100000000000000,
            page: 1,
          });
  
          this.colegiados30Dias = this.filtrarColegiadosPorDias(dataColegiados.resultados, 30);
          this.colegiados60Dias = this.filtrarColegiadosPorDias(dataColegiados.resultados, 60);
          this.colegiados90Dias = this.filtrarColegiadosPorDias(dataColegiados.resultados, 90);
  
          this.carregando = false;
        } catch (err) {
          console.error(err);
          this.carregando = false;
          this.erro = true;
        }
      },
      filtrarColegiadosPorDias(colegiados, dias) {
        return colegiados.filter(colegiado => this.calcularDiasRestantes(colegiado) <= dias && this.calcularDiasRestantes(colegiado) > (dias - 30));
      },
      calcularDiasRestantes(elemento) {
        if (elemento.data_termino !== null) {
          const dataTerminoObj = new Date(elemento.data_termino);
          const dataAtual = new Date();
          const diferencaEmMilissegundos = dataTerminoObj - dataAtual;
          const diasRestantes = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
          return diasRestantes;
        }
      },
    },
  };
  </script>
  

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
      .div-conteudo {
          width: 100vw;
          margin-bottom: 5rem;
          .centro {
              max-width: 96vw;
              margin: 0 auto;
          }
          .index {
              h2 {
                  font-family: "Montserrat-Bold";
                  color: #005516;
                  font-size: 1.3rem;
                  text-align: center;
              }
              .topo-index {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 1.5rem;
                  margin-top: 1.5rem;

                  h1 {
                      font-family: "Montserrat-Bold";
                      color: #ffa600 !important;
                      font-size: 1.8rem;
                  }
              }
              .grid {
                  display: grid;
                  grid-template: "a b";
                  gap: 1rem;
              }
              .cessoes {
                  width: 100%;
                  margin: 0rem auto;
                  .container-tabela {
                      margin: 1rem auto;
                      border-radius: 1.5rem;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                  }
              }
              /*.disposicoes {
                  width: fit-content;
                  .container-tabela {
                      margin: 1rem auto;
                      border-radius: 1.5rem;
                      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                      width: 46vw;
                  }
              }*/
          }
      }
  }
  @media screen and (min-width: "60px") and (max-width: "1065px") {
      .div-conteudo {
          width: 100vw;

          .centro {
              width: 90vw;
              margin: 0 auto;
          }

          .index {
              .cessoes {
                  width: 100%;
                  margin: 0rem auto;
              }
              .disposicoes {
                  width: fit-content;
                  margin: 2rem 0 1rem 0;
              }
              h2 {
                  font-family: "Montserrat-Bold";
                  color: #005516;
                  font-size: 1.3rem;
                  text-align: center;
              }
              .topo-index {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 1.5rem;
                  margin-top: 1.5rem;

                  h1 {
                      font-family: "Montserrat-Bold";
                      color: #ffa600 !important;
                      font-size: 1.8rem;
                  }

                  a {
                      color: #005516;
                      text-decoration: none;
                      font-family: "Montserrat-Medium";
                      border: #005516 solid 0.15rem;
                      padding: 0.3rem 2rem;
                      border-radius: 0.2rem;
                      transition: 0.2s;
                      margin-bottom: 1.5rem;
                      margin-top: 1.5rem;

                      span {
                          font-family: "Montserrat-Bolder";
                      }
                  }

                  a:hover {
                      color: white;
                      background-color: #005516;
                  }
              }

              .caixa-pesquisa {
                  background-color: #dbdbdb;
                  padding: 1rem;
                  border-radius: 1rem 1rem 0 0;

                  .row4-data {
                      display: flex;
                      justify-content: space-between;
                      gap: 1.5rem;

                      .item {
                          width: -webkit-fill-available;
                      }
                  }

                  input {
                      border-radius: 0.5rem;
                      border: solid 0.1rem;
                  }

                  h2 {
                      margin: 0;
                      text-transform: uppercase;
                      color: #005516;
                      font-size: 1.2rem;
                      font-family: "Montserrat-Bold";
                      cursor: pointer;
                  }

                  .item {
                      display: grid;
                      margin: .5rem 0 .5rem 0;

                      label {
                          width: fit-content;
                          margin-bottom: 0.3rem;
                          color: black;
                          font-family: "Montserrat-Medium";
                          font-size: 0.9rem;
                          font-weight: 500;
                      }

                      input {
                          height: 2rem;
                          font-family: "Montserrat-Medium";
                          padding-left: 0.5rem;
                          font-size: 0.9rem;
                      }

                      select {
                          height: 2rem;
                          border-radius: 0.5rem;
                          border: solid 0.1rem;
                          background-color: white;
                          font-family: "Montserrat-Medium";
                          font-size: 0.84rem;
                      }
                  }

                  .botoes-pesquisa {
                      display: grid;
                      gap: 1rem;
                      place-content: center;
                      margin-top: 1rem;

                      .bt-pesquisa {
                          text-align: center;

                          button {
                              height: 2.5rem;
                              background-color: #005516;
                              color: white;
                              border: #005516 solid 0.15rem;
                              font-size: 1rem;
                              padding: 0.3rem 2rem;
                              border-radius: 2rem;

                              img {
                                  height: 1rem;
                                  margin-right: 0.5rem;
                              }
                          }

                          button:hover {
                              text-decoration: underline;
                          }
                      }

                      .bt-limpar {
                          text-align: center;

                          button {
                              height: 2.5rem;
                              background-color: #FFFFFF;
                              color: #000;
                              border: white solid 0.15rem;
                              font-size: 1rem;
                              padding: 0.3rem 2rem;
                              border-radius: 2rem;

                              img {
                                  height: 1.5rem;
                                  margin-right: 0.5rem;
                              }
                          }

                          button:hover {
                              text-decoration: underline;
                          }
                      }
                  }
              }

              .n-div {
                  display: flex;
                  justify-content: space-between;

                  .nresultados {
                      margin: 0.5rem 0.5rem 0.5rem 0.5rem;

                      label {
                          color: #005516;
                          font-size: 0.8rem;
                          font-family: "Montserrat-Bold";
                          margin-top: 0.5rem;
                      }
                  }

                  .npagina {
                      margin: 0.5rem 0.5rem 0.5rem 0.5rem;
                      text-align: end;

                      label {
                          color: #005516;
                          font-size: 0.8rem;
                          font-family: "Montserrat-Medium";
                          margin: 0.5rem .5rem 0 0;
                      }

                      select {
                          border: none;
                          background-color: #edf2ee;
                          font-size: 0.9rem;
                          margin-top: 0.5rem;
                          cursor: pointer;
                      }
                  }
              }

              .container-tabela {
                  margin-top: .5rem;
                  border-radius: 1.5rem;
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                  .resultados {
                      width: 100%;
                      height: max-content;
                      margin: 0;
                  }
              }
          }
      }
  }
  .modal-mask {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: table;
      transition: opacity 0.3s ease;
  }
  .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
  }
  .modal-container {
      width: fit-content;
      height: 0px auto;
      margin: 0px auto;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      font-size: 1.5rem;
      font-family: "Montserrat-Medium";

      img {
          width: 2rem;
          height: 2rem;
          margin-right: 1.6rem;
      }

      label {
          font-size: 1.3rem;
          color: rgba(255, 255, 255, 0.556);
          display: inline;
          position: relative;
          top: 0rem;
      }
  }
</style>
