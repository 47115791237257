<template>
  <div>
    <TipoMandatoForm :tipoMandato="null" />
  </div>
</template>

<script>
import TipoMandatoForm from "../../components/tipo_mandato/TipoMandatoForm.vue";

export default {
  name: "TipoMandatoNew",
  components: {
    TipoMandatoForm,
  },
};
</script>

<style lang="scss" scoped></style>

  