<template>
  <div id='anexos'>
    <!-- <button type="button" @click="adicionarAnexos()">Adicionar anexo</button> -->
    <div class="itemTop">
      <label><b>Arquivo PDF: </b></label>
        <div class="bt-incluir">
          <button type="button" @click="adicionarAnexos()">
            <span>+</span> Adicionar
          </button>
        </div>
    </div>
    <template v-for="(anexos, i) in local">
      <template>
        <div class="upload-container" :key="anexos.id">
          <div class="bt-desfazer" v-if="anexos._destroy">
            <button type="button" @click="desfazer(i)">
              Desfazer exclusão
            </button>
          </div>
          <template v-else>
            <div class="caixa-form item">
              <!-- Editar -->
              <div class="container" v-if="anexos.id">
                  <button type="button" @click="removerAnexos(anexos, i)">
                    <img src="../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                  </button>
                  <label>Título: </label>
                  <input type="text" placeholder="Digite o título do DOE" v-model="anexos.titulo" disabled />
                  <label> Anexo: </label>
                  <a v-bind:href="urlPdf(anexos.id)" target="_blank">{{anexos.arquivo}}</a>
              </div>
        
              <!-- Adicionar -->
              <div class="container" v-else>
                  <button type="button" @click="removerAnexos(anexos, i)">
                    <img src="../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                  </button>
                  <div class="label-input">
                    <label> Título: </label>
                    <input type="text" placeholder="Digite o titulo do anexo" v-model="anexos.titulo" />
                  </div>
        
                  <!-- Digitou o Titulo -->
                  <template v-if="anexos.titulo">
                    <div class="file-upload">
                      <div>
                        <label for="file-upload" class="custom-file-upload">
                          Escolher arquivo
                        </label>
                        <input type="file" id="file-upload" name="anexos_attributes[]arquivo" ref="file" 
                          accept="application/pdf" @change="onFileChange($event)" />
                      </div>
                      <label>
                        Arquivo: {{ anexos.aux ? anexos.aux.name : 'nenhum' }}
                      </label> 
                    </div>
                    <div v-if="anexos.aux != null" hidden>
                      {{ anexos.arquivo = anexos.aux }}
                    </div>
                  </template>
        
                  <!-- Não Digitou o Titulo -->
                  <template v-else>
                    <div class="file-upload">
                      <div>
                        <label for="file-upload" class="custom-file-upload-disable">
                          Escolher o arquivo
                        </label>
                        <input type="file" disable />
                      </div>
                      <label>
                        Arquivo: {{ anexos.aux ? anexos.aux.name : 'nenhum' }}
                      </label>
                    </div>
                    {{anexos.aux = null}}
                  </template>
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { cloneDeep, tap } from "lodash";
  import ColegiadoApi from "../services/ColegiadoApi.js";

  export default {
    name: "MultipleUploads",
    props: ["value"],
    data(){
      return{
        carregando: true,
        erro: false,
        filess: null,
        titulo: null,
        arquivo: null,
        tamanho: null,
        tamanhoF: -1,
      }
    },
    computed: {
      local() {
        return this.value ? this.value : [];
      },
    },
    created() {
      this.colegiadoApi = new ColegiadoApi();
    },
    methods: {
      urlPdf: function(anexos) {
        return `${process.env.VUE_APP_BASE_API_URL}/api/v1/colegiados/anexo/${anexos}`;
      },
      desfazer(i) {
        let clone;
        clone = tap(cloneDeep(this.local), (v) => {
          v[i]._destroy = false;
        });
        this.$emit("input", clone);
      },
      adicionarAnexos() {
        if(this.local.length < 1) {
          this.$emit(
            "input",
            tap(cloneDeep(this.local), (v) =>
              v.push({
                id: null,
                titulo: null,
                arquivo: File,
                _destroy: null,
                aux: null,
              })
            )
          );
        }
      },
      removerAnexos(anexos, i) {
        let clone; 
        if (anexos.id) {
          clone = tap(cloneDeep(this.local), (v) => {
            v[i] = { ...v[i], _destroy: true };
          });
        } else {
          clone = tap(cloneDeep(this.local), (v) => v.splice(i, 1));
        }
        this.$emit("input", clone);
      },
      async onFileChange(e) {
        if (e.target.files && e.target.files.length > 0) {
          
          /* RESOLVIDO OS PROBLEMA DE SUBSTITUIÇÃO DE ARQUIVOS */
          let tamanhoV = this.value.length - 1;
          this.tamanhoF++;
          this.value[tamanhoV].aux = e.target.files[0];
          this.value[tamanhoV].arquivo = e.target.files[0];
        } else {
          this.value[0].arquivo = null;
        }
      },
    },
  };
</script>


<style lang="scss" scoped>
  .upload-container {
    .caixa-form {
      border-radius: 1rem;
    }
    .container {
      margin: 0;
    }
  }

  @media screen and (min-width: "1066px") {
    .itemTop{
      display: inline;
      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }
      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin: 0 0 0 .5rem;
          span {
            font-family: "Montserrat-Bolder";
          }
        }
        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .container {
      padding: .7rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      max-width: -webkit-fill-available;

      button{
        border: none;

        img{
          cursor: pointer;
          width: 1rem;
          height: max-content;
          display: inline-block;
        }

        img:hover{
          width: 1rem;
          padding: .1rem;
        }
      }

      .label-input {
        display: flex;
        align-items: center;
        gap: .7rem;

        label {
          margin: 0;
        }

        input {
          width: -webkit-fill-available;
        }
      }

      .file-upload {
        display: flex;
        align-items: center;
        gap: .5rem;

        label {
          width: max-content;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: fit-content;
          margin: 0;
        }
      }
    }
    .bt-desfazer{
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
        resize: vertical;
        font-size: 17px;
    }
    .caixa-form {
      background-color: #efefef;
      border-radius: 0.2rem;

      input{
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled{
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        color: #005516;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #005516 solid 0.15rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin: 0 0 0 .5rem;
        cursor: pointer;
      }

      .custom-file-upload:hover {
        color: white;
        background-color: #005516;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: solid 0.1rem grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }
      .item {
        display: grid;
        margin-bottom: 0.3rem;
        /* margin: 1rem 0; */
        label {
          display: inline;
          
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }
        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }
        a{
          color: #ffa600;
        }
        a:hover{
          color: #e49503;
        }
        .aaa{
          color: #efefef;
        }
      }
    }
  }

  @media screen and (min-width: "60px") and (max-width: "1065px") {
    .itemTop{
      display: inline;
      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }
      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;
          span {
            font-family: "Montserrat-Bolder";
          }
        }
        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .container {
      img{
        cursor: pointer;
        width: 23px;
        padding: 0.2rem;
        height: 1.4rem;
        display: inline-block;
      }
      button{
        padding-left: 0.2rem;
        padding-right: 0.05rem;
        border-radius: 1rem;
        border: none;
      }
      p{
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }
    .bt-desfazer{
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
        resize: vertical;
        font-size: 17px;
    }
    .caixa-form {
      background-color: #efefef;
      border-radius: 0.2rem;
      input{
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }
      input:disabled{
        background: white;
      }
      input[type="file"] {
        display: none;
      }

      @media screen and (max-width:"699px"){
        .caixa-form {
          background-color: #efefef;
          border-radius: 0.2rem;
          input{
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }
          input:disabled{
            background: white;
          }
          input[type="file"] {
            display: none;
          }
          .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
          }
          .custom-file-upload-disable {
            border-radius: 0.5rem;
            border: solid 0.1rem grey;
            display: inline-block;
            padding: 6px 12px;
            margin: .3rem .4rem 0 .4rem;
            background-color: white;
            color: grey;
          }
          .item {
            display: grid;
            margin-bottom: 0.3rem;
            /* margin: 1rem 0; */
            label {
              display: inline;
              
              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
            }
            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }
            a{
              color: #ffa600;
            }
            a:hover{
              color: #e49503;
            }
            .aaa{
              color: #efefef;
            }
          }
        }
      }

      @media screen and (min-width:"700px"){
        .custom-file-upload {
          border-radius: 0.5rem;
          border: solid 0.1rem;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          cursor: pointer;
        }
        .custom-file-upload-disable {
          border-radius: 0.5rem;
          border: solid 0.1rem grey;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          background-color: white;
          color: grey;
        }
      }

      .item {
        display: grid;
        margin-bottom: 0.3rem;
        /* margin: 1rem 0; */
        label {
          display: inline;
          
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }
        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }
        a{
          color: #ffa600;
        }
        a:hover{
          color: #e49503;
        }
        .aaa{
          color: #efefef;
        }
      }
    }
  }  
</style>