<template>
  <div>
    <div>
      <div class="form centro">
        <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
        <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>
        <form @submit.prevent="adicionarOuAtualizar">
          <div class="caixa-form item">
            <div v-if="mandatosForm.status_mandatos_id === 2">
              <div class="item">
                <label><b>{{ possuiAsterisco("Código do Mandato Anterior:") }}</b></label>
                <input type="text" placeholder="Digite o Código do Mandato Anterior" v-model="mandatosForm.mandatos_id" />
              </div>
            </div>
            <div class="item">
              <label><b>{{ possuiAsterisco("CPF do Membro:") }}</b></label>
              <the-mask :mask="['###.###.###-##']" placeholder="Digite o CPF do membro" v-model="cpf_membro" />
            </div>
            <div class="item">
              <button id="btn-carregar-dados" class="btn-carregar-dados" @click.prevent="buscarDadosMembro(cpf_membro)">
                <img id="spinner-loading-carregar" class="spinner-loading" src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                <p id="conteudo-botao-carregar">Carregar Dados</p>
              </button>
            </div>
            <div class="item">
              <label><b>Nome do Membro:</b></label>
              <input type="text" placeholder="Nome do membro" v-model="nome_membro" disabled/>
            </div>
            <div v-if="isServidorPublico && !contemCargo">
              <div class="item">
                <label for="selectedOrgao">Cargo:</label>
                <v-select
                  class="vselect"
                  v-model="mandatosForm.cargo_membro"
                  id="selectedCargo"
                  :options="cargos"
                  label="descricaoCargo"
                  placeholder="Selecione um cargo">
                </v-select>
              </div>
            </div>
            <div v-if="isServidorPublico && contemCargo">
              <div class="item">
                <label for="selectedOrgao">Cargo:</label>
                <input type="text" placeholder="Digite o nome" v-model="mandatosForm.cargo_membro" disabled/>
              </div>
            </div>
            <div class="flexbox-2">
              <div class="item">
                <div class="radio">
                  <label class="radio-label"><b>{{ possuiAsterisco("Tipo de Membro") }}</b></label>
                  <div class="radio-item">
                    <label for="titular">Titular</label>
                    <input type="radio" id="titular" name="TipoMembroOptions" value="titular" v-model="mandatosForm.tipo_membro">
                    <label for="suplente">Suplente</label>
                    <input type="radio" id="suplente" name="TipoMembroOptions" value="suplente" v-model="mandatosForm.tipo_membro">
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="radio">
                  <label class="radio-label"><b>{{ "Função" }}</b></label>
                  <div class="radio-item">
                    <label for="presidente">Presidente</label>
                    <input type="radio" id="presidente" name="PresidenteOptions" value="presidente" v-model="mandatosForm.funcao">
                    <label for="vicepresidente">Vice-presidente</label>
                    <input type="radio" id="vicepresidente" name="VicePresidenteOptions" value="vicepresidente" v-model="mandatosForm.funcao">
                  </div>
                </div>
              </div>
            </div>
            <div class="flexbox-2">
              <div class="item">
                <label><b>{{ possuiAsterisco("Data de início do Mandato:") }}</b></label>
                <date-picker
                  v-model="mandatosForm.data_inicio"
                  format="DD/MM/YYYY"
                  type="date"
                  placeholder="Selecione uma data de início do Mandato">
                </date-picker>
              </div>
              <div class="item">
                <label><b>{{ possuiAsterisco("Data de término do Mandato:") }}</b></label>
                <date-picker
                  v-model="mandatosForm.data_termino"
                  format="DD/MM/YYYY"
                  type="date"
                  placeholder="Selecione uma data de término do Mandato">
                </date-picker>
              </div>
            </div>
            <div class="item">
              <label for="selectedCategoria"><b>{{ "Categoria Representada:" }}</b></label>
              <v-select
                class="vselect"
                v-model="mandatosForm.categorias_representativas_id"
                id="selectedCategoria"
                :options="categoria_representativa"
                label="nome"
                :reduce="nome => nome.id">
              </v-select>
            </div>
            <div class="flexbox-2">
              <div class="item">
                <label><b>{{ "Ato de Nomeação:" }}</b></label>
                <input type="text" placeholder="Digite o ato de Nomeação" v-model="mandatosForm.ato_nomeacao" />
              </div>
              <div class="item">
                <label><b>{{ "Link do Ato de Nomeação:"}}</b></label>
                <input type="text" placeholder="Digite o link do ato de Nomeação" v-model="mandatosForm.link_ato_nomeacao" />
              </div>
            </div>
            <div class="flexbox-2" v-if="flagContinuidade == true || this.mandatosForm.mandatos_id != null">
              <div class="item">
              <label for="selectedCodigo"><b>{{ "Código do mandato anterior:" }}</b></label>
              <v-select
                class="vselect"
                v-model="mandatosForm.mandatos_id"
                id="selectedCodigo"
                :options="mandatos_ids"
                label="id"
                :reduce="id => id.id">
              </v-select>
              </div>
              
              <div class ="item"></div>
              <div class ="item"></div>
            </div>
            <div class="flexbox-2">
              <div class="item">
                <label for="selectedTipoMandato"><b>{{ possuiAsterisco("Tipo de Mandato:") }}</b></label>
                <v-select
                  class="vselect"
                  v-model="mandatosForm.tipos_mandatos_id"
                  id="selectedTipoMandato"
                  :options="tipo_mandato"
                  label="nome"
                  :reduce="nome => nome.id"
                  @input="onTipoMandatoChange">
                </v-select>
              </div>
              <div class="item">
                <label for="selectedStatus"><b>{{ possuiAsterisco("Status:") }}</b></label>
                <v-select
                  class="vselect"
                  v-model="mandatosForm.status_mandatos_id"
                  id="selectedStatus"
                  :options="status"
                  label="nome"
                  :reduce="nome => nome.id">
                </v-select>
              </div>
            </div>
            <div class="bt-salvar-tab">
              <button id="botao-salvar-tab" title="Criar/Atualizar" type="submit">
                <img id="spinner-loading-tab" class="spinner-loading" src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                <p id="conteudo-botao-tab">{{ mandatosForm.id ? "Atualizar Mandato" : " Adicionar Mandato" }}</p>
              </button>
            </div>
          </div>
            
        </form>     
      </div>
      <div class= "formulario" v-if="mandatosList.length !== 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Código Mandato</th>
                    <th>CPF</th>
                    <th>Nome</th>
                    <th>Cargo</th>
                    <th>Função</th>
                    <th>Tipo de Membro</th>
                    <th>Tipo de Mandato</th>
                    <th>Mandato Anterior</th>
                    <th>Data de Início</th>
                    <th>Data de Término</th>
                    <th>Categoria Representada</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="mandato in mandatosList" :key="mandato.id">
                    <td class>{{ mandato.id }}</td>
                    <td>{{ formatarCPF(mandato.cpf_membro) }}</td>
                    <td>{{ mandato.nome_membro }}</td>
                    <td>{{ mandato.cargo_membro }}</td>
                    <td>{{ getFuncao(mandato.funcao)}}</td>
                    <td>{{ getTipo(mandato.tipo_membro) }}</td>
                    <td>{{ mandato.tipo_mandato }}</td>
                    <td>{{ mandato.mandatos_id }}</td>
                    <td>{{ formatarData(mandato.data_inicio) }}</td>
                    <td>{{ formatarData(mandato.data_termino) }}</td>
                    <td>{{ mandato.categoria_representativa }}</td>
                    <td>{{ mandato.status_mandato }}</td>
                    <td>
                      <div class="mandatos-button">
                        <button class="edit-button" @click.prevent="editarMandato(mandato, true)">
                          <img src="../../assets/imagens/icon-edit-green.svg" alt="Editar mandato">
                        </button>
                        <button class="delete-button" @click.prevent="abrirModalExclusao(mandato)">
                          <img src="../../assets/imagens/icon-delete-red.svg" alt="Excluir mandato">
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <thead class="footer">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-else>
              <table class="table">
                <thead >
                  <tr>
                    <th>Código Mandato</th>
                    <th>CPF</th>
                    <th>Nome</th>
                    <th>Cargo</th>
                    <th>Tipo de Membro</th>
                    <th>Tipo de Mandato</th>
                    <th>Código Mandato Anterior</th>
                    <th>Data de Início</th>
                    <th>Data de Término</th>
                    <th>Categoria Representada</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <p class="sem-resultados"> Nenhum resultado encontrado </p>
                </tbody>
                <thead class="footer">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              </table>
            </div>
      <div v-if="modalExclusaoVisivel" class="modal-exclusao">
      <div class="modal-mask">
        <div class="modal-container">
          <div class="modal-header">
            <h2>Deseja mesmo excluir este Mandato?</h2>
          </div>
          <div class="modal-body">
            <p>Dados do Mandato:</p>
            <div class="dados">
              <div class="flexbox">
                <div class="texto-centralizado">
                  Código Mandato: <span>{{ mandatoSelecionado.id }}</span>
                </div>
                <div class="texto-centralizado">
                  CPF: <span>{{ mandatoSelecionado.cpf_membro }}</span>
                </div>
                <div class="texto-centralizado">
                  Nome: <span>{{ mandatoSelecionado.nome_membro }}</span>
                </div>
                <div class="texto-centralizado">
                  Cargo: <span>{{ mandatoSelecionado.cargo_membro }}</span>
                </div>
                <div class="texto-centralizado">
                  Função: <span>{{ mandatoSelecionado.funcao }}</span>
                </div>
              </div>
              <div class="flexbox">
                <div class="texto-centralizado">
                  Tipo de Membro: <span>{{ mandatoSelecionado.tipo_membro }}</span>
                </div>
                <div class="texto-centralizado">
                  Tipo de Mandato: <span>{{ mandatoSelecionado.tipo_mandato }}</span>
                </div>
                <div class="texto-centralizado">
                  Data de Início: <span>{{ formatarData(mandatoSelecionado.data_inicio) }}</span>
                </div>
                <div class="texto-centralizado">
                  Data de Término: <span>{{ formatarData(mandatoSelecionado.data_termino) }}</span>
                </div>
              </div>
              <div class="flexbox">
                <div class="texto-centralizado">
                  Categoria Representada: <span>{{ mandatoSelecionado.categoria_representativa }}</span>
                </div>
                <div class="texto-centralizado">
                  Status: <span>{{ mandatoSelecionado.status_mandato }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn-confirmar-exclusao" @click="confirmarExclusaoMandato">Sim</button>
            <button class="btn-cancelar-exclusao" @click="fecharModalExclusao">Não</button>
          </div>
        </div>
      </div>
      </div>
      <div v-if="modalConfirmacaoAtualizacaoVisivel" class="modal-exclusao">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <h2>Deseja realmente atualizar este mandato?</h2>
        </div>
        <div class="modal-footer">
          <button class="btn-confirmar-exclusao" @click="confirmarAtualizacaoMandato">Sim</button>
          <button class="btn-cancelar-exclusao" @click="fecharModalConfirmacaoAtualizacao">Não</button>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div> 
</template>

<script>
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/pt-br";
  import DatePicker from "vue2-datepicker";
  import vSelect from 'vue-select';
  import "vue-select/dist/vue-select.css";
  import MsgErroFormularios from '../MsgErroFormularios';
  import MandatosApi from '../../services/MandatosApi';
  import MembroApi from '../../services/MembroApi';
  //import { parseISO } from "date-fns";

  export default {
    name: 'MandatosForm',
    props: {
      colegiadoId: {
        type: Number,
        required: false,
      }
    },
    data() {
      return {
        carregando: false,
        erro: false,
        enviandoForm: false,
        isServidorPublico: false,
        contemCargo: false,
        dadosmembros: [],
        msgErros: {
          cpf: null,
          nome: null,
          tipo_membro: null,
          funcao: null,
          data_inicio: null,
          data_termino: null,
          categoria_representativa: null,
          ato_nomeacao: null,
          link_nomecao: null,
          cargo: null,
          tipo: null,
          status: null,
          default: null,
        },
        status: [],
        categoria_representativa: [],
        tipo_mandato: [],
        mandatos_ids:[],
        cargos: [],
        nome_membro: null,
        cpf_membro: null,
        mandatosList: [],
        mandatosForm: {
          id: null,
          tipo_membro: null,
          funcao: null,
          data_inicio: null,
          data_termino: null,
          categorias_representativas_id: null,
          ato_nomeacao: null,
          link_ato_nomeacao: null,
          cargo_membro: null,
          membros_id: null,
          tipos_mandatos_id: null,
          status_mandatos_id: null,
          colegiados_id: this.colegiadoId,
          mandatos_id: null,
        },
        orgaos: [],
        flagContinuidade: null,
        selectedTipoMandato: null,
        selectedStatus: null,
        selectedCategoria: null,
        selectedCodigo: null,
        search: '',
        offset: 0,
        limit: 10,
        modalExclusaoVisivel: false,
        mandatoSelecionado: null,
        modalConfirmacaoAtualizacaoVisivel: false,
        confirmarAcao: null,
        edicaoMandato: false,
      };
    },
    components: {
      MsgErroFormularios,
      vSelect,
      DatePicker,
    },
    created() {
      this.mandatosApi = new MandatosApi();
      this.membroApi = new MembroApi();
      this.carregarTipoMandato();
      this.carregarCategoria();
      this.carregarStatus();
      this.carregarMandatos();
    },
    computed: {
      filtered() {
        return this.orgaos.filter((orgao) =>
          orgao.nome.toLowerCase().includes(this.search.toLowerCase())
        )
      },
      paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset)
      },
      hasNextPage() {
        const nextOffset = this.offset + this.limit
        return Boolean(
          this.filtered.slice(nextOffset, this.limit + this.offset).length
        )
      },
      hasPrevPage() {
        const prevOffset = this.offset - this.limit
        return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
        )
      },
    },
    methods: {
      formatarCPF(cpf) {
      cpf = cpf.replace(/\D/g, '');

      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

      return cpf;
      },
      abrirModalExclusao(mandato) {
        this.mandatoSelecionado = mandato;
        this.modalExclusaoVisivel = true;
      },
      fecharModalExclusao() {
        this.modalExclusaoVisivel = false;
        this.mandatoSelecionado = null;
      },
      confirmarExclusaoMandato() {
      if (this.mandatoSelecionado) {
        this.excluirMandato(this.mandatoSelecionado.id);
        this.fecharModalExclusao();
      }
    },
    abrirModalConfirmacaoAtualizacao() {
    this.modalConfirmacaoAtualizacaoVisivel = true;
    },

    fecharModalConfirmacaoAtualizacao() {
      this.modalConfirmacaoAtualizacaoVisivel = false;
      this.confirmarAcao = null;
    },

    confirmarAtualizacaoMandato() {
      console.log(this.mandatos_id)
      if (this.edicaoMandato === true) {
        this.edicaoMandato = false;
        this.adicionarOuAtualizar();
      } else {
        this.resetarFormulario();
      }
      this.fecharModalConfirmacaoAtualizacao();
      },
      editarMandato(mandato, valor) {
        this.edicaoMandato = valor;
        this.mandatosForm = { ...mandato };
        this.cpf_membro = mandato.cpf_membro;
        this.nome_membro = mandato.nome_membro;
        this.mandatosForm = {
          ...mandato,
          data_inicio: mandato.data_inicio ? new Date(mandato.data_inicio) : null,
          data_termino: mandato.data_termino ? new Date(mandato.data_termino) : null,
        };
        
        if (mandato.tipo_mandato) {
          this.onTipoMandatoChange(mandato.tipo_mandato.codigo);
        }
        this.mandatos_ids = this.mandatos_ids.filter(item => item.id !== mandato.id);
        
      },
      excluirMandato(id) {
        this.mandatosList = this.mandatosList.filter(mandato => mandato.id !== id);
        
        if(id !== null) {
          this.excluirLinha(id)
        }
      },
      getFuncao: function (value) {
        if (value === 'vicepresidente'){
          return 'Vice-Presidente';
        }
        else if (value == 'presidente'){
          return 'Presidente';
        }
      },
      getTipo: function(value){
        if(value === 'titular'){
          return 'Titular';
        }
        else if(value === 'suplente'){
          return 'Suplente';
        }
      },
      async excluirLinha(mandatoId) {
      try {
        this.carregando = true;
        this.erro = false;

        if (this.mandatosApi.cancelTokens.destroyMandato) {
          this.mandatosApi.cancelTokens.destroyMandato.cancel();
        }

        await this.mandatosApi.destroyMandato(mandatoId);

      } catch (err) {
        if (this.mandatosApi.isCancel(err)) {
          return;
        }

        this.carregando = false;
        this.erro = true;
      }
      },
      async adicionarOuAtualizar() {
        try {
          this.carregando = true;
          if(this.edicaoMandato === true){
            this.abrirModalConfirmacaoAtualizacao();
          }else{
          
          const validationResult = this.validarForm(this.mandatosForm);

          if (validationResult.flag === 1) {
            this.erro = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.msgErros = validationResult.errors;
            return;
          }
          const data = await this.mandatosApi.getMembroId(this.cpf_membro);
          let membro_id = data[0].id;
          this.mandatosForm.membros_id = membro_id;
          if (this.mandatosForm.id) {
            const index = this.mandatosList.findIndex(mandato => mandato.id === this.mandatosForm.id);
            if (index !== -1) {
              this.$set(this.mandatosList, index, { ...this.mandatosForm });
            }
          } else {
            const novoMandato = { ...this.mandatosForm };
            //novoMandato.id = this.mandatosList.length ? Math.max(...this.mandatosList.map(m => m.id)) + 1 : 1;
            
            this.mandatosList.push(novoMandato);
          }
          
          this.resetarFormulario();
          this.salvarTabela();
          setTimeout(() => {
            this.carregando = false;
          }, 1000);
        }
        } catch (error) {
          this.carregando = false;
          this.erro = true;

          const { response } = error;
          if (response && response.data) {
            this.msgErros.default = response.data.mensagem;
          } else {
            this.msgErros.default =
              "Houve um erro ao salvar o registro. Por favor tente novamente.";
          }

          console.error("Erro ao adicionar ou atualizar mandato:", error);
        }
      },
      onTipoMandatoChange(selectedId) {
        const selectedMandato = this.tipo_mandato.find(mandato => mandato.id === selectedId);

        if (selectedMandato && selectedMandato.codigo === 3) {
          this.flagContinuidade = true;
        } else if (selectedMandato && selectedMandato.codigo != 3) { // Verifica se selectedMandato não é undefined
          this.flagContinuidade = false;
          this.mandatosForm.mandatos_id = null;
        } else {
          this.flagContinuidade = false;
        }
      },
      resetarFormulario() {
        this.mandatosForm = {
          id: null,
          tipo_membro: null,
          data_inicio: null,
          data_termino: null,
          categorias_representativas_id: null,
          ato_nomeacao: null,
          link_ato_nomeacao: null,
          cargo_membro: null,
          membros_id: null,
          tipos_mandatos_id: null,
          status_mandatos_id: null,
          colegiados_id: this.colegiadoId,
          mandatos_id: null,

        };
        this.flagContinuidade = false;
        delete this.mandatosForm.cpf
        this.nome_membro = null;
        this.cpf_membro = null;
        this.isServidorPublico = false; // Resetar o estado
        this.comtemCargo = false;
      },
      atualizarErro(novoErro) {
        this.erro = novoErro;
      },
      fecharErro() {
        this.erro = false;
        this.erroModal = false;
      },
      onSearch(query) {
        this.search = query;
        this.offset = 0;
      },
      inRange(valor) {
        let divMensagem = document.getElementById("mensagem");

        if (divMensagem) {
          if (valor > 99 || valor < 1) {
            divMensagem.style.display = "block";
          } else {
            divMensagem.style.display = "none";
          }
        }
      },
      resize(event) {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      },
      possuiAsterisco(texto) {
        return '* ' + texto;
      },
      carregarMandatos: async function () {
        try {
          if (this.mandatosApi.cancelTokens.getMandatos) {
            this.mandatosApi.cancelTokens.getMandatos.cancel();
          }

          const data = await this.mandatosApi.getMandatos({
              colegiados_id: this.$route.params.id,
              qtd_por_pagina: 100,
              page: this.pagina,
          });

          this.mandatosList = data.resultados
          this.mandatos_ids = data.resultados;
        } catch (err) {
          if (this.mandatosApi.isCancel(err)) {
            return;
          }
          console.log(err);

          this.mandatosList = null;
        }
      },
      async carregarTipoMandato() {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false;
          this.tipo_mandato = await this.mandatosApi.getTipoMandato();
        } catch (err) {
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.tipo_mandato = [];
        }
      },
      async carregarCategoria() {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false;
          this.categoria_representativa = await this.mandatosApi.getCategoria();
        } catch (err) {
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.categoria_representativa = [];
        }
      },
      async carregarStatus() {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false;
          this.status = await this.mandatosApi.getStatus();
        } catch (err) {
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.status = [];
        }
      },
      async buscarDadosMembro(cpf) {
        this.erro = false;
        this.cargos = [];
        this.msgErros.erromembro = '';
        try {
          
          let marcador = 0;
          const dadoscpf = await this.mandatosApi.getMembros(cpf);
          this.dadosmembros = dadoscpf;
          
          this.dadosmembros.map((membro) => {
            if(membro.cpf == cpf) {
              this.nome_membro = membro.nome;
              this.cpf_membro = membro.cpf;
              this.isServidorPublico = membro.servidor_publico;
              this.cargos = [];
                marcador = 1;
            }
          })
          if(marcador == 0) {
              this.msgErros.erromembro = "Esse servidor não está cadastrado! Por favor, clique em novo servidor."
              this.erro = true;
              this.nome_membro = null;
              this.cpf_membro = null;
              this.cargos = [];
          }
          if(this.isServidorPublico){
            const dados = await this.membroApi.getDadosRhNet(cpf);
            if (this.isServidorPublico) {
              this.nome_membro = dados.nome;
              this.cargos.push(dados.cargo);
              if(this.cargos[0] === "NENHUM CARGO ENCONTRADO"){
                  this.mandatosForm.cargo_membro = this.cargos[0];
                  this.contemCargo = true;
              }
            }
          }
        } catch (err) {
          this.carregando = false;
          this.erro = true;

          this.nome_membro = null;
          this.cpf_membro = null;
          this.cargos = [];

          const { response } = err;
          if (response && response.data) {
            this.msgErros.default = response.data.mensagem;
          } else {
            this.msgErros.default =
              "Houve um erro ao salvar o registro. Por favor tente novamente.";
          }
        }
      },
      async salvarTabela() {
        this.enviandoForm = true;
        try {
        
          (this.mandatosList)
          // Salvar dados da tabela no banco de dados
          for (const mandato of this.mandatosList) {
            this.mandatosApi.setMandatos(mandato);
            
          }
         
          // Redirecionar para a tela ColegiadoShow
          this.$emit('form-saved');

        } catch (err) {
          this.erro = true;
          const { response } = err;
          if (response && response.data) {
            this.msgErros.default = response.data;
          } else {
            this.msgErros.default = 'Houve um erro ao salvar o registro. Por favor, tente novamente.';
          }
        } finally {
          this.enviandoForm = false;
        }
      },
      validarForm(mandatosForm) {
        let flag = 0;
        const errors = {
          cpf: null,
          nome: null,
          tipo_membro: null,
          data_inicio: null,
          data_termino: null,
          data: null,
          mandatos_id: null,
          //categoria_representativa: null,
          //ato_nomeacao: null,
          //link_nomecao: null,
          tipo: null,
          status: null,
          codigos_mandato_anterior: null,
          default: null,
        };
        if (!this.cpf_membro) {
          errors.cpf = 'O campo CPF não foi preenchido.';
          flag = 1;
        }
        if (!this.nome_membro) {
          errors.nome = 'O campo nome não foi preenchido.';
          flag = 1;
        }
        if (!mandatosForm.tipo_membro) {
          errors.tipo_membro = 'O tipo do membro não foi preenchido.';
          flag = 1;
        }
        if (!mandatosForm.data_inicio) {
          errors.data_inicio = 'A Data de início não foi preenchida.';
          flag = 1;
        }
        if (!mandatosForm.data_termino) {
          errors.data_termino = 'A Data de término não foi preenchida.';
          flag = 1;
        }/*
        if (!mandatosForm.categorias_representativas_id) {
          errors.categoria_representativa = 'A categoria representativa não foi preenchida.';
          flag = 1;
        }
        if (!mandatosForm.ato_nomeacao) {
          errors.ato_nomeacao = 'O ato da nomeação não foi preenchido.';
          flag = 1;
        }
        if (!mandatosForm.link_ato_nomeacao) {
          errors.link_nomecao = 'O link da nomeação não preenchido.';
          flag = 1;
        }*/
        if (!mandatosForm.tipos_mandatos_id) {
          errors.tipo = 'O tipo do mandato não preenchido.';
          flag = 1;
        }
        if (!mandatosForm.status_mandatos_id) {
          errors.status = 'O status do mandato não preenchido.';
          flag = 1;
        }
        if (this.flagContinuidade == true) {
          if(!mandatosForm.mandatos_id){
            errors.codigos_mandato_anterior = 'O codigo do mandato anterior não foi preenchido.';
            flag = 1;
          }
        }
        
        if (mandatosForm.data_inicio && mandatosForm.data_termino) {
          const dataInicio = new Date(mandatosForm.data_inicio);
          const dataTermino = new Date(mandatosForm.data_termino);

          if (dataInicio >= dataTermino) {
            errors.data = "A data de início do Mandato não pode ser maior ou igual a Data de término do mesmo.";
            flag = 1;
          }
        }

        const duracaoMandato = this.getDuracaoMandato();
        const inicioMandato = new Date(mandatosForm.data_inicio);
        const terminoMandato = new Date(mandatosForm.data_termino);
        const duracaoMandatoAtual = (terminoMandato - inicioMandato) / (1000 * 60 * 60 * 24 * 365);

        if (duracaoMandatoAtual > duracaoMandato) {
          errors.data = `A duração do mandato não pode ser maior que ${duracaoMandato} anos.`;
          flag = 1;
        }

        this.msgErros = errors;
        return { flag, errors };
      },
      async getDuracaoMandato() {
        const colegiadoId = this.colegiadoId;
        const colegiado = await this.mandatosApi.getColegiado(colegiadoId);
        return colegiado.duracao_mandato;
      },
      formatarData(data) {
        if (!data) return '';
        const dataObj = new Date(data);
        return dataObj.toLocaleDateString('pt-BR');
      },
      getTipoMandato(tipo_mandato_id) {
        const tipoMandato = this.tipo_mandato.find(tm => tm.id === tipo_mandato_id);
        return tipoMandato ? tipoMandato.nome : '';
      },
      getStatusNome(status_mandatos_id) {
        const status = this.status.find(st => st.id === status_mandatos_id);
        return status ? status.nome : '';
      },
      getCategoriaRepresentada(categorias_representativas_id){
        const categoria = this.categoria_representativa.find(cr => cr.id === categorias_representativas_id);
        return categoria ? categoria.nome : '';
      },
    },
  };
</script>

<style lang="scss" scoped>
    /* Remove as setas de aumento e diminuição do input tipo "number" */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      appearance: textfield;
    }

    * {
      padding: 0;
      margin: 0;
      font-family: 'Karla', sans-serif;
    }

    .mx-datepicker {
      border: none;
    }

    .pagination {
      display: flex;
      gap: 5rem;
      margin: 0.5rem 0.5rem 0 .5rem;
    }

    .pagination button {
      display: inline-block;
      border: none;
      background-color: #005516;
      font-weight: bold;
      font-size: .8rem;
      cursor: pointer;
      color: white;
      border-radius: 2rem;
      flex-grow: 1;
    }

    .pagination button:hover {
      background-color: #4CAF50;
      cursor: pointer;
    }

    div.item.radio {
      display: grid;
      gap: 0rem;
      align-items: center;
      width: fit-content;
      cursor: default;
    }

    .radioContainer {
      display: flex;
      gap: 1rem;

      .radioTrue {
        font-size: 1rem;
        align-items: center;
        display: flex;
        gap: .5rem;
      }

      .radioFalse {
        font-size: 1rem;
        align-items: center;
        display: flex;
        gap: .5rem
      }
    }
    .formulario{
      padding-left: 3%;
      padding-right: 3%;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 1rem 0 0 0;
    }
    
    thead {
      display: table-header-group;
    }

    .table th,
    .table td {
      border: 1px solid #ddd;
      padding: .3rem .6rem;
      text-align: center;
      align-content: center;
      font-size: .8rem;
    }

    .table th {
      background-color: #f2f2f2;
      color: black;
      font-size: .8rem;
      display: table-cell;
    }

    th:first-child {
      border-radius: 1.5rem 0 0 0;
      padding-left: 1rem;
      border-left: none;
      border-top: none;
      width: 50px;
    }

    th:last-child {
      border-radius: 0 1.5rem 0 0;
      border-right: none;
      border-top: none;
      padding-right: 1rem;
    }

    thead.footer {
      display: table-footer-group;
      height: 2rem;

      .table th,
      .table td {
        border: 1px solid #ddd;
        padding: .3rem .6rem;
        text-align: center;
        align-content: center;
        font-size: .8rem;
      }

      .table th {
        background-color: #f2f2f2;
        color: black;
        font-size: .8rem;
        display: table-cell;
      }

      th:first-child {
        border-radius: 0 0 0 1.5rem;
        border-bottom: none;
        border-top: 1px solid #ddd;
        padding-left: 1rem;
      }
      th:nth-child(2), td:nth-child(2) {
        width: 150px; /* Aumente este valor conforme necessário */
      }
      th:last-child {
        border-radius: 0 0 1.5rem 0;
        border-bottom: none;
        border-top: 1px solid #ddd;
        padding-right: 1rem;
      }
    }

    p.sem-resultados {
      width: max-content;
      height: fit-content;
      margin: 1rem 0 1rem .5rem;  
    }

    button {
      margin: .5rem 0 0 0;
      padding: .3rem 1rem;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: .4rem;
    }

    button:hover {
      background-color: #0056b3;
    }

    .mandatos-button {
      display: flex;
      gap: 1rem;
      margin: 0 auto;
      width: fit-content;
      height: fit-content;

      button.edit-button {
        background: none;
        margin: 0;
        padding: 0;

        img {
          width: 1rem;
          height: 1rem;
        }

        img:hover {
          padding: .15rem;
        }
      }

      button.delete-button {
        background: none;
        margin: 0;
        padding: 0;

        img {
          width: 1rem;
          height: 1rem;
        }

        img:hover {
          padding: .15rem;
        }
      }
    }
    .modal-exclusao {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: fit-content;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 0 .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }
        }
        .modal-footer {
          margin: .7rem auto 0rem auto;
          place-content: space-evenly;
          font-size: 1rem;

          .btn-confirmar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #005516;
            color: #f2f2f2;
          }

          .btn-cancelar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #bd0000;
            color: #f2f2f2;
          }
        }
      }

    @media screen and (min-width: "1066px") {
      .observacaoConvenio {
        margin: .5rem 0 0 0;

        p {
          font-size: 1rem;
          font-weight: bold;
          color: red;
        }

        span {
          font-size: .9rem;
          color: red;
        }
      }

      .wrapper {
        width: 100%;
        min-height: 100vh;
        background-color: #f8f8f8;
        margin: 0;
        padding: 20px;
      }

      h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.8rem;
      }

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }
      }

      .caixa-form {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .btn-carregar-dados {
          width: fit-content;
          margin: 0;
        }

        .gridbox-1 {
          display: grid;
          width: -webkit-fill-available;
        }

        .flexbox-1 {
          display: grid;
          grid-template-columns: 2fr 1fr;
          gap: 2rem;
        }

        .flexbox-2 {
          display: flex;
          justify-content: space-between;
          gap: 2rem;
        }

        .flexbox-2-button {
          display: flex;
          justify-content: space-between;
          gap: 2rem;
        }

        .flexbox-3 {
          display: grid;
          grid-template: "a b c";
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1.5rem;
          align-items: start;
        }

        input,
        the-mask {
          border-radius: 0.5rem;
          border: solid 0.1rem;
        }

        input[type="file"] {
          display: none;
        }

        input:disabled {
          background: white;
        }

        h2 {
          margin-top: 0;
          text-transform: uppercase;
          color: #005516;
          font-size: 1.2rem;
          font-family: "Montserrat-Bold";
          cursor: pointer;
        }

        .servidorForm {
            padding: 0 2rem;
        }

        #divNomeServidor {
            visibility: hidden;
            height: fit-content;
        }

        .servidorButton {
            display: inline-block;
            border: none;
            background-color: transparent;
            color: #00a339;
            font-weight: bold;
            font-size: .8rem;
            margin-left: 0.5rem;
            cursor: pointer;
        }

        .servidorButton:hover {
            text-decoration: underline;
        }

        .servidorButtonDisabled {
            border: none;
            background-color: transparent;
            color: gray;
            font-weight: normal;
            font-size: .8rem;
            margin-left: 0.5rem;
            cursor: not-allowed;
        }

        .item {
            display: grid;
            margin: .5rem 0;
            width: -webkit-fill-available;
            height: fit-content;
            span {
                width: fit-content;
                display: inline-block;
            }
            .input-container {
                display: flex;
                align-items: center;
            }
            .label-container {
                display: flex;
                gap: 80px; 
            }
            .input-wrapper input {
                width: 100px; 
                margin-right: 10px; 
            }
            .input-wrapper1 input {
                width: 100px; 
                margin-right: 110px; 
            }
            .input-wrapper2 input {
                width: 100px; 
                margin-right: 10px; 
            }
            .input-wrapper3 input {
                width: 100px; 
                margin-right: 10px; 
            }
            .radio {
                display: inline-flex;
                align-items: center;
                height: fit-content;
                width: fit-content;
            }
            .radio-label {
                margin-bottom: 0;
            }
            .radio-item {
                display: flex;
                align-items: center;
                label {
                    margin: 0 0 0 .8rem;
                    font-size: .8rem;
                }
                input {
                    width: fit-content;
                    margin-left: .5rem;
                }
            }
            label {
                display: inline;
                margin-bottom: 0.3rem;
                color: black;
                font-family: "Montserrat-Medium";
                font-size: 0.9rem;
            }
            input {
                height: 2rem;
                font-family: "Montserrat-Medium";
                padding-left: 0.5rem;
                font-size: 0.9rem;
            }
            a:link {
                color: #ffa600;
            }
            a:link:hover {
                color: #e49503;
            }
            select {
                height: 2rem;
                width: 100%;
                border-radius: 0.5rem;
                border: solid 0.1rem;
                background-color: white;
                font-family: "Montserrat-Medium";
                font-size: 0.95rem;
            }
            /*  .upload {
                            color: #ffa600;
                            border-radius: 0.5rem;
                            border: #ffa600 solid 0.124rem;
                            display: inline-block;
                            padding: 2px 6px;
                            font-size: 0.9rem;
                            transition: 0.2s;
                            margin-left: 0.4rem;
                            margin-right: 0.4rem;
                            font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                            color: white;
                            background-color: #ffa600;
                        } */
            .textarea {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 0 0 1px #000;
                border: 5px solid transparent;
                outline: none;
                padding: 4px;
                min-height: 150px;
                box-sizing: border-box;
                font-family: "Montserrat-Medium";
                font-size: 0.93rem;
                color: black;
            }
            .textarea:focus {
                outline: 1px solid !important;
                box-shadow: 0 0 0 2px black;
            }
        }
      }

      .bt-salvar {
          text-align: center;
          margin: 1.5rem 0 0 0;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-salvar-tab {
          text-align: center;
          margin: 1rem;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-excluir {
          img {
              cursor: pointer;
              width: 23px;
              padding: 0.2rem;
              height: 1.4rem;
              display: inline-block;
          }

          button {
              border-radius: 1rem;
              border: none;
          }
      }

      .spinner-loading {
          visibility: hidden;
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          margin: 0rem .5rem 0rem -0.5rem;
      }

      #botao-salvar:hover {
          background-color: #4CAF50;
      }

      #botao-salvar-tab:hover {
          background-color: #4CAF50;
      }
    }

    @media screen and (min-width: "60px") and (max-width: "1065px") {
        .observacaoConvenio {
            margin: .5rem 0 0 0;
            p {
                font-size: 1rem;
                font-weight: bold;
                color: red;
            }
            span {
                font-size: .9rem;
                color: red;
            }
        }
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            border: 2px solid #00a339;

            .btn-carregar-dados {
                width: fit-content;
                margin: 0;
            }

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }
            .gridbox-1 {
                display: grid;
                width: -webkit-fill-available;
            }
            .servidorForm {
                padding: 0 2rem;
            }
            #divNomeServidor {
                visibility: hidden;
                height: fit-content;
            }
            .servidorButton {
                display: inline-block;
                border: none;
                background-color: transparent;
                color: #00a339;
                font-weight: bold;
                font-size: .8rem;
                margin-left: 0.5rem;
                cursor: pointer;
            }
            .servidorButton:hover {
                text-decoration: underline;
            }
            .servidorButtonDisabled {
                border: none;
                background-color: transparent;
                color: gray;
                font-weight: normal;
                font-size: .8rem;
                margin-left: 0.5rem;
                cursor: not-allowed;
            }
            .item {
                display: grid;
                margin: 1rem 0;

                .flexbox-2 {
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                }

                .flexbox-2-button {
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                }

                span {
                    width: fit-content;
                    display: inline-block;
                    .servidorForm {
                        box-shadow: #000 3px 5px 15px;
                        border-radius: 2rem;
                        padding: 1rem;
                    }
                    .servidorButton {
                        display: inline-block;
                        border: none;
                        background-color: transparent;
                        color: #00a339;
                        font-weight: bold;
                        font-size: .8rem;
                        margin-left: 0.5rem;
                        cursor: pointer;
                    }
                    .servidorButton:hover {
                        text-decoration: underline;
                    }
                    .servidorButtonDisabled {
                        border: none;
                        background-color: transparent;
                        color: gray;
                        font-weight: normal;
                        font-size: .8rem;
                        margin-left: 0.5rem;
                        cursor: not-allowed;
                    }
                }

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                        color: #ffa600;
                        border-radius: 0.5rem;
                        border: #ffa600 solid 0.124rem;
                        display: inline-block;
                        padding: 2px 6px;
                        font-size: 0.9rem;
                        transition: 0.2s;
                        margin-left: 0.4rem;
                        margin-right: 0.4rem;
                        font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                        color: white;
                        background-color: #ffa600;
                        } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: calc(100vw - 5.5rem);
            }
        }

        .caixa-form-blocos {
            background-color: #efefef;
            padding: 1rem;
            border-radius: 0.2rem;

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: block;
                margin: 1rem 0;

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                        color: #ffa600;
                        border-radius: 0.5rem;
                        border: #ffa600 solid 0.124rem;
                        display: inline-block;
                        padding: 2px 6px;
                        font-size: 0.9rem;
                        transition: 0.2s;
                        margin-left: 0.4rem;
                        margin-right: 0.4rem;
                        font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                        color: white;
                        background-color: #ffa600;
                        } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: 100%;
            }
        }

        .blocos-textos {
            margin: 1rem 0rem;
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-salvar-tab {
            text-align: center;
            margin: 1rem;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-excluir {
            img {
                cursor: pointer;
                width: 23px;
                padding: 0.2rem;
                height: 1.4rem;
                display: inline-block;
            }

            button {
                border-radius: 1rem;
                border: none;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }

        #botao-salvar-tab:hover {
            background-color: #4CAF50;
        }
    }

    @media screen and (max-width: "700px") {
        .caixa-form {
            .item { 
                .flexbox-2 {
                    display: grid;
                    justify-content: normal;
                    gap: 0rem;
                }

                .flexbox-2-button {
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                }
            }    
        } 
    }

    .modal-servidor {
        visibility: visible;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 20vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
        }

        .modal-container {
            height: 100%;
            padding: .3rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
                height: fit-content;
                width: fit-content;
                margin: .5rem auto .3rem auto;
                display: grid;
                gap: 1.5rem;

                h2 {
                    font-size: 1.1rem;
                    font-weight: bold;
                    text-align: center;
                }
            }

            .modal-body {
                border: 1px solid gray;
                border-radius: 1rem;
                text-align: left;

                .item {
                    margin: .5rem 1.5rem;
                    label {
                        font-size: 1rem;
                        font-weight: bold;
                        margin-top: .3rem;
                        margin-bottom: .2rem;
                        margin-left: 1rem;
                        color: #005516;
                    }
                }

            }

            .modal-footer {
                margin: .5rem auto 0rem auto;
                place-content: space-evenly;
                font-size: .9rem;

                .btn-salvar-servidor {
                    border: none;
                    border-radius: .5rem;
                    width: 25%;
                    height: 1.8rem;
                    background-color: #005516;
                    color: #f2f2f2;
                }
                .btn-fechar-servidor {
                    border: none;
                    border-radius: .5rem;
                    width: 25%;
                    height: 1.8rem;
                    background-color: rgb(203, 1, 1);
                    color: #f2f2f2;
                }
            }
        }
    }
</style>